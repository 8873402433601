// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBLoadingManagement
} from 'mdb-react-ui-kit';

import "react-toggle/style.css";



class CouponWithLoginForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {
                    isPayment: false       
      };
  };

    onCustomer = (e) => {
      e.preventDefault();
      let self = this;
        this.setState({isPayment: true})
        this.props.context.interAxios.post('/couponorder/existingcustomer',
        {
          itemCount: 1
          }, function(response) {
            if(response.data.paymentUrl !== ""){
              self.props.context.closeLoginModal()
              self.props.context.openLoginModal("succesRegistration");
              setTimeout(() => {
                //self.props.context.closeLoginModal();
                window.open(response.data.paymentUrl,"_self").focus();
                
              }, 2000);
            }else {
              alert('A vásárlás sikertelen! Ellenörizd a megadott adatokat!')
            }
                
          }, function (error) {
            alert('A vásárlás sikertelen! Ellenörizd a megadott adatokat!')
        });
    }
  
  render() {
    
    return (<>
              <form onSubmit={(e) =>  {if(!this.state.isPayment){
                                                  this.onCustomer(e)}}} className="profile-form" autoComplete="off">
                <div className="white-panel">

                  <MDBRow className="py-2 m-0">
                    <MDBCol md='12' className="p-0">
                      <MDBRow className="pb-2 m-0 align-items-center justify-content-center" >
                          <span className="modal-title-form" style={{width: "auto"}}>Megrendelés</span>
                      </MDBRow>
                      <MDBRow className="py-2 m-0 d-flex align-items-center justify-content-center">
                          <div className="d-grid align-items-center justify-content-center apply-card-modal green">
                          <span className="title">Videótár egy havi ajándékutalvány</span>
                    {/*<span className="description">- ONLINE VIDEÓTÁR valami marketing szöveg...</span>*/}
                    <div className="price">
                        <MDBRow style={{width: "100%"}} className="ps-4 pt-2">
                        <span className="description">Díj,</span>
                        </MDBRow>
                        <MDBRow className=" text-center">
                        <span className="price-text">2990 Ft</span>
                        </MDBRow>
                        
                        
                    </div>
                          </div>
                    
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                 
                  {this.state.isPayment && (
                    <div ref={this.props.colorsRef} style={{ height: "70px", width: "100%", zIndex: "1029" }}>
                      <MDBLoadingManagement color='success' parentRef={this.props.colorsRef} backdropColor='blue' backdropOpacity={0.2} />
                    </div>
                  )}
                      
                        <MDBRow center className="pb-3 m-0">
                          <MDBCol md='12' className="p-0">
                                <div className="d-flex justify-content-center align-items-center pt-3">
                                  <button type="submit" className={!this.state.isPayment ? "rergistration-button modal-button green" : "rergistration-button modal-button black"} ref={this.props.buttonRef}>
                                    <span>Vásárlás</span>
                                  </button>
                                </div>
                          </MDBCol>
                        </MDBRow>
       
                  </div>
              </form>

               
      </>
    );
  }
};

function CouponWithLoginFormFunc(props) {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const emailRef = useRef();
  const nameRef = useRef();
  const addressRef = useRef();
  const postcodeRef = useRef();
  const cityRef = useRef();
  const buttonRef = useRef();
  const buttonRef_2 = useRef();
  const colorsRef = useRef(null);
  return <CouponWithLoginForm searchParams={searchParams} 
                      navigate={navigate} 
                      hideModal={props.hideModal} 
                      context={props.context} 
                      emailRef={emailRef}
                      nameRef={nameRef}
                      addressRef={addressRef}
                      postcodeRef={postcodeRef}
                      cityRef={cityRef}
                      buttonRef={buttonRef}
                      buttonRef_2={buttonRef_2}
                      colorsRef={colorsRef}
                      />;
}

export default CouponWithLoginFormFunc;
