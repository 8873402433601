// react imports
import React, { useRef, useContext, Component } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import backgroundImage from '../../assets/media/images/hero_image.jpg'
import insta from '../../assets/media/icons/1.png'
import face from '../../assets/media/icons/2.png'
import twitter from '../../assets/media/icons/3.png'
import CountDown from "../common/timer/CountDown";
// CUSTOM COMPONENTS
import AppContext from '../../AppContext';

import { MDBRow, 
         MDBCol,
         MDBContainer,
         MDBLoadingManagement
          } from 'mdb-react-ui-kit';

class KapcsolatokPage extends Component {


  constructor(props) {
    super(props);
      this.state = {email: "",
                    nev: "",
                    text: "",
                    isLoading: false
      };
    };

 


    onCustomer = (e) => {
      e.preventDefault();
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(this.state.email.match(validRegex)){
        this.setState({
          isLoading: true
        })
        let self = this;
        this.props.context.interAxios.post('/contact', {
                                                name: this.state.nev,
                                                email: this.state.email,
                                                message: this.state.text
                                               }, 
        function(response) {
          self.props.context.openLoginModal("uzenet")
          self.setState({
            email: "",
            nev: "",
            text: "",
            isLoading: false
          })
        });
        
      }else {
        alert('Az Emailforámatum helytelen!')
      }
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
  
 
  
render() {
  let imageHeight =  document.getElementById("backgroundImage")?.height;
  return (
    <div>
      <div className={this.props.context.getState().liveTime !== undefined  ? "text-box live" : "text-box"}>
          <span className="social-title">Kövess a Social Médián!</span>
          <div>
            
            <a href="https://www.instagram.com/rekarubint/"  target="_blank" rel="noopener noreferrer"><img src={insta}/></a>
            <a href="https://www.facebook.com/rubintreka"  target="_blank" rel="noopener noreferrer"><img src={face}/></a>
            <a href="https://www.tiktok.com/@rubint.reka"  target="_blank" rel="noopener noreferrer"><img src={twitter}/></a>
          </div>
      </div>
      <div className="box"/>
       <div className="kapcsolatok" id="kapcsolatok">
          <img src={backgroundImage} id="backgroundImage" className={"backgroundImage-style"}/>
          <MDBContainer style={{maxWidth: "660px"}}>
            
            <form onSubmit={(e) =>  {if(!this.state.isLoading){
                                                  this.onCustomer(e)}}} className="kapcsolatok-form" autoComplete="off">
              <div className="kapcsolatok-title d-flex align-items-center justify-content-center">
                <MDBRow style={{maxWidth: "660px"}} className="m-0 pb-5">
                    <span>Küldje el üzenetét!</span>
                </MDBRow>
              </div>  
                <div className="white-panel">
                  {/** EMAIL **/}
                  <MDBRow className="pb-4 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="nev">Név</label><br/>
                      <input
                        id='nev'
                        name='nev'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.nev}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  {/** PASSWORDs **/}
                  <MDBRow className="pb-4 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="password">Email</label><br/>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="pb-4 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="text">Üzenet</label><br/>
                      <textarea 
                        id='text'
                        name='text'
                        type='textarea'
                        onChange={this.changeHandler}
                        value={this.state.text}
                        rows="4" 
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  {this.state.isLoading && (
                    <div ref={this.props.colorsRef} style={{ height: "70px", width: "100%", zIndex: "1029" }}>
                      <MDBLoadingManagement color='success' parentRef={this.props.colorsRef} backdropColor='blue' backdropOpacity={0.2} />
                    </div>
                  )}

                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-center" >
                          <div>
                            <button type="submit" className="rergistration-button modal-button green" >
                            <span>Küldés</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>

                  <div className="kapcsolatok-footer pt-3">
                    <MDBRow center style={{maxWidth: "660px"}} className="m-0 pb-2">
                        <p>Rendezvényekkel, fellépésekkel kapcsolatos információk:</p>
                    </MDBRow>
                    <MDBRow style={{maxWidth: "660px"}} className="m-0">
                        <p className="normal-text m-0"><span className="bold-text">E-mail: </span><a style={{color: "#ffffff", textDecorationLine: "underline"}} href={"mailto:rendezveny@rubintreka.hu"}> rendezveny@rubintreka.hu</a></p>
                    </MDBRow>
                   {/* <MDBRow style={{maxWidth: "660px"}} className="m-0 pb-2">
                        <p className="normal-text"><span className="bold-text">Tel: </span><a style={{color: "#ffffff", textDecorationLine: "underline"}} href={"tel:+36 30 222 00 11"}> +36 30 222 00 11</a></p>
  </MDBRow>*/}
                    <MDBRow style={{maxWidth: "660px"}} className="m-0 pb-2">
                        <p>Business, marketing, szponzoráció vagy együttműködés:</p>
                    </MDBRow>
                    <MDBRow style={{maxWidth: "660px"}} className="m-0">
                        <p  className="normal-text"><span className="bold-text">E-mail: </span><a style={{color: "#ffffff", textDecorationLine: "underline"}} href={"mailto:sales@rubintreka.hu"}> sales@rubintreka.hu</a></p>
                    </MDBRow>
                  </div>  

                </div>
            </form>
          </MDBContainer>
        </div>
       
    </div>


  );
}
 
};

function KapcsolatokPageFunc(props) {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const colorsRef = useRef(null);
  
  return <KapcsolatokPage searchParams={searchParams} navigate={navigate}  context={context} colorsRef={colorsRef}/>;
}

export default KapcsolatokPageFunc;