import React from "react";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from 'mdb-react-ui-kit';


const UzenetModal = (props) => {



    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={props.visible}  className="altalanos-modal">
      <MDBModalDialog scrollable centered>
        <MDBModalContent>
          <MDBModalHeader>
            
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title">Köszönjük!</span></MDBModalTitle>
       
              <div className="justify-content-center d-flex align-ites-center pt-3" >
                <button type="button" className="rergistration-button modal-button green" onClick={() => {props.hideModal()}} >
                <span>Bezár</span>
                </button>
              </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default UzenetModal;