import React, { Component } from 'react';
import AppContext from './AppContext';
import InterAxios from './Service/InterAxios';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const getAuthToken = () => {
  return cookies.get('authtoken')?.token;
}


class AppContextProvider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      interAxios: new InterAxios('App', getAuthToken),
      isGyik: true,
      activeItem: "1",
    };
  }

 
  componentDidMount() {
    let self = this;
    if(this.state.fotoList === undefined){
      this.state.interAxios.get('/coverimage', function(response) {
        self.setState({fotoList: response.data})
      });
    }
    if(this.state.randomVideoList === undefined){
      this.state.interAxios.get('/video/random', function(response) {
        self.setState({randomVideoList: response.data})
      });
    }
  }
  
  
  setState(state) {
    super.setState(function(prevState, props) {
      let newState = {...prevState, ...state};
      window.localStorage.setItem('state', JSON.stringify(newState));
      return {
        ...newState
      };
    })
  }


  getState = () => {
    let stateStr = window.localStorage.getItem('state');
    let stateObj = JSON.parse(stateStr);
    return stateObj;
  }

  getConsent = () => {
    let stateStr = window.localStorage.getItem('consentMode');
    if (stateStr === null) {
      return null;
    }
    let stateObj = JSON.parse(stateStr);
    return stateObj;
  }

  setConsent = (newConsent) => {
    window.localStorage.setItem('consentMode', JSON.stringify(newConsent));
  }

  clearState = () => {

  }

  onSignIn = (responseData) => {
    cookies.set('authtoken', {
        token: responseData.authToken
      }, { path: '/'}
    );
    cookies.set('client', {
      status: responseData.subscriptionStatus
    }, { path: '/'}
    
    );
    if(responseData.subscriptionStatus === "INACTIVE"){
      setTimeout(() => {
      //this.openLoginModal("firstwarning")}, 500)
      this.openLoginModal("lastwarning")}, 500)
    }
    if(responseData.subscriptionStatus === "INACTIVE_24"){
      setTimeout(() => {
      this.openLoginModal("lastwarning")}, 500)
    }
    this.setState({loggedIn: true, isGyik: false});
  }


  onLogout = () => {
   cookies.remove('authtoken', { path: '/'});
   cookies.remove('isMoreVideos', { path: '/'})
    window.localStorage.removeItem('state');
    this.setState({loggedIn: false, isGyik: true});
    this.setState({isMoreVideos: false})
  }

  isLoggedIn = () => {
      let cookieToken = getAuthToken();
      return cookieToken !== undefined && this.parseJwt(cookieToken)?.exp > (Date.now() / 1000);
  }

  parseJwt = (token) => {
      try {
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));

          return JSON.parse(jsonPayload);
      } catch (e) {
          console.log(e);
        return null;
      }
  };

  openLoginModal = (modalName) => {
    this.setState({
      loginModalOpened: true,
      modalName: modalName
    });
  }

  isLoginModalOpened = () => {
    return this.getState()?.loginModalOpened;
  }
  
  closeLoginModal = () => {
    this.setState({
      loginModalOpened: false,
    });
    document.body.style.overflowY = 'scroll'
  }

  onHideScroll = (value) => {
    this.setState({isScroll: value})
  }

  handleWindowSizeChange = (videoWidht) => {
    this.setState({videoWidht: videoWidht});
  };

  onSetActiveItem = (tab) => {
    this.setState({activeItem: tab})
  }

  onSideNavOpen = (params) => {
    this.setState({isSideNavOpen: params})
  }

  onSetLiveTime = (time) => {
    this.setState({liveTime: time})
  }

  onSetNewLiveVideo = (video) => {
    this.setState({newLiveVideo: video})
  }

  onSetNewLiveName = (name) => {
    this.setState({newLiveName: name})
  }

  onPaymentProcess = (paymentUrl, paymentService) => {
    this.setState({paymentUrl: paymentUrl, paymentService: paymentService})
  }

  onRenderTrue = () => {
    this.setState({isRender: true})
  }

  onRenderFalse = () => {
    this.setState({isRender: false})
  }

 setIsLoading = (isLoading) => {
    this.setState({isLoading: isLoading})
  }

  onSetBookmarkList = (bookmarkList) => {
    this.setState({bookmarkList: bookmarkList})
  }

  onSetVideolist = (videoList) => {
    this.setState({videoList: videoList})
  }

  onSetNewVideolist = (newVideoList) => {
    this.setState({newVideoList: newVideoList})
  }

  onSetCategorylist = (categoryList) => {
    this.setState({categoryList: categoryList})
  }
  onSetIsMoreVideos = () => {
    cookies.set('isMoreVideos', {
      isMoreVideos: true
    }, { path: '/'})
    this.setState({isMoreVideos: true})
    
  }

  

  render() {
      return (
          <AppContext.Provider
              value={{
                  interAxios: this.state.interAxios,
                  isLoggedIn: this.isLoggedIn,
                  onSignIn: this.onSignIn,
                  onLogout: this.onLogout,
                  getState: this.getState,
                  clearState: this.clearState,
                  isLoginModalOpened: this.isLoginModalOpened,
                  openLoginModal: this.openLoginModal,
                  closeLoginModal: this.closeLoginModal,
                  onHideScroll: this.onHideScroll,
                  handleWindowSizeChange: this.handleWindowSizeChange,
                  onSetActiveItem: this.onSetActiveItem,
                  onSideNavOpen: this.onSideNavOpen,
                  onSetLiveTime: this.onSetLiveTime,
                  onSetNewLiveVideo: this.onSetNewLiveVideo,
                  onPaymentProcess: this.onPaymentProcess,
                  onRenderTrue: this.onRenderTrue,
                  onRenderFalse: this.onRenderFalse,
                  setIsLoading: this.setIsLoading,
                  onSetBookmarkList: this.onSetBookmarkList,
                  onSetVideolist: this.onSetVideolist,
                  onSetNewVideolist: this.onSetNewVideolist,
                  onSetCategorylist: this.onSetCategorylist,
                  onSetIsMoreVideos: this.onSetIsMoreVideos,
                  onSetNewLiveName: this.onSetNewLiveName,
                  setConsent: this.setConsent,
                  getConsent: this.getConsent
              }}
          >
              {this.props.children}
          </AppContext.Provider>
      );
  }
}

export default AppContextProvider;
