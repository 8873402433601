import React from "react";
import mobile from '../../assets/media/images/mobilrame.png'
import nyeremeny_text from '../../assets/media/images/play-text.png'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
} from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const JatekModal = (props) => {


// STATES



/*const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      context.interAxios.post('/auth/google', {token: tokenResponse.access_token}, function(response) {
        context.onSignIn(response.data);
        context.closeLoginModal();
      });
    },
    onError: (error) => alert('Login Failed:', error)
});*/


    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={props.visible}  className="nyeremeny-modal">
      <MDBModalDialog  centered>
        <MDBModalContent>
          <MDBModalHeader>
            
            <MDBBtn className='btn-close' color='none' onClick={() => {cookies.set('nyeremeny', {isNyeremeny: true}, { path: '/'});
                                                                      document.getElementById("nyeremenyplayer").style.display='none'; 
                                                                      props.hideModal()}}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
          <div className="nyeremeny-jatek">
                <div className="jatek-block">
                    <img className="nyeremeny-text" src={nyeremeny_text} alt=""/>
                    <div className="jatek-div">
                    <img className="mobile-image" src={mobile} alt=""/>
                    
                    <div className="nyeremeny-video">
                        <iframe id="nyeremenyplayer" src={cookies.get('nyeremeny')?.isNyeremeny !== true ? "https://player.vimeo.com/video/883295908?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" : ""} ></iframe>
                    </div>
                    </div>
                </div>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default JatekModal;