import React, { useContext  } from "react";

import AppContext from '../../AppContext';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from 'mdb-react-ui-kit';


const SimplePayModalTorles = (props) => {

const context = useContext(AppContext);

// router hooks

// STATES


/*const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      context.interAxios.post('/auth/google', {token: tokenResponse.access_token}, function(response) {
        context.onSignIn(response.data);
        context.closeLoginModal();
      });
    },
    onError: (error) => alert('Login Failed:', error)
});*/


    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={props.visible}  className="altalanos-modal">
      <MDBModalDialog scrollable centered>
        <MDBModalContent>
          <MDBModalHeader>
            
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title">Ön megszakította a fizetést!</span></MDBModalTitle>
              <div className="justify-content-center d-flex align-ites-center pt-3" >
                <button type="button" className="rergistration-button modal-button green" onClick={() => {props.hideModal(); setTimeout(() => {
            context.openLoginModal("signin")
          }, 500)}} >
                <span>Belépek</span>
                </button>
              </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
export default SimplePayModalTorles;
