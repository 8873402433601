// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBLoadingManagement
} from 'mdb-react-ui-kit';

import "react-toggle/style.css";



class CouponWithoutLoginForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "",
                    fullName: "",
                    address: "",
                    postalCode: "",
                    city: "",
                    isPayment: false       
      };
  };

    onCustomer = (e) => {
      e.preventDefault();
      let self = this;
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(this.state.email.match(validRegex)){
        this.setState({isPayment: true})
          this.props.context.interAxios.post('/couponorder/newcustomer',
          {
            newCustomer: {
              email: this.state.email,
              billing: {
                name: this.state.fullName,
                postcode: this.state.postalCode,
                country: "HU",
                region: "",
                location: this.state.city,
                address: this.state.address,
                vatCode: ""
              }
            },
            itemCount: 1
            }, function(response) {
              if(response.data.paymentUrl !== ""){
                self.props.context.closeLoginModal()
                self.props.context.openLoginModal("succesRegistration");
                setTimeout(() => {
                  //self.props.context.closeLoginModal();
                  window.open(response.data.paymentUrl,"_self").focus();
                  
                }, 2000);
              }else {
                alert('A vásárlás sikertelen! Ellenörizd a megadott adatokat!')
              }
                  
            }, function (error) {
              alert('A vásárlás sikertelen! Ellenörizd a megadott adatokat!')
          });
          
      }else {
        alert('Az Emailformátum helytelen!')
      }
   
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
  
  render() {
    
    return (<>
              <form onSubmit={(e) =>  {if(!this.state.isPayment){
                                                  this.onCustomer(e)}}} className="profile-form" autoComplete="off">
                <div className="white-panel">
                  {/** EMAIL **/}
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6'>
                      <label htmlFor="email">E-mail cím*</label>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        ref={this.props.emailRef}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.nameRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="py-2 m-0">
                    <MDBCol md='6' className="p-0">
                      <MDBRow className="pb-2 m-0">
                        <MDBCol md='12' size="12">
                          <span className="modal-title-form">Számlázási adatok</span>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="py-2 m-0">
                        <MDBCol md='12'>
                          <label htmlFor="address">Számlázási név</label>
                          <input
                            id='fullName'
                            name='fullName'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.fullName}
                            ref={this.props.nameRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.addressRef.current.focus()
                              }
                            }}
                            required
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="pb-2 m-0">
                        <MDBCol md='12' size="12">
                          <label htmlFor="address">Utca, házszám*</label>
                          <input
                            id='address'
                            name='address'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.address}
                            ref={this.props.addressRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.postcodeRef.current.focus()
                              }
                            }}
                            required
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="pb-5 m-0">
                        <MDBCol md='4' size="12">
                        <label htmlFor="postalCode">Irányítószám*</label>
                          <input
                            id='postalCode'
                            name='postalCode'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.postalCode}
                            ref={this.props.postcodeRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.cityRef.current.focus()
                              }
                            }}
                            required
                          />
                        </MDBCol>
                        <MDBCol md='8'>
                        <label htmlFor="city">Város*</label>
                          <input
                            id='city'
                            name='city'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.city}
                            ref={this.props.cityRef}
                            required
                          /> 
                        </MDBCol>
                      </MDBRow>
                      
                     
                    </MDBCol>
                    <MDBCol md='6' className="p-0">
                      <MDBRow className="pb-2 m-0">
                        <MDBCol md='12'>
                          <span className="modal-title-form">Megrendelés</span>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="py-2 m-0">
                        <MDBCol md='12'>
                          <div className="d-grid align-items-center justify-content-center apply-card-modal green">
                          <span className="title">Videótár egy havi ajándékutalvány</span>
                    {/*<span className="description">- ONLINE VIDEÓTÁR valami marketing szöveg...</span>*/}
                    <div className="price">
                        <MDBRow style={{width: "100%"}} className="ps-4 pt-2">
                        <span className="description">Díj,</span>
                        </MDBRow>
                        <MDBRow className=" text-center">
                        <span className="price-text">2990 Ft</span>
                        </MDBRow>
                        
                        
                    </div>
                          </div>
                        </MDBCol>
                    
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                 
                  {this.state.isPayment && (
                    <div ref={this.props.colorsRef} style={{ height: "70px", width: "100%", zIndex: "1029" }}>
                      <MDBLoadingManagement color='success' parentRef={this.props.colorsRef} backdropColor='blue' backdropOpacity={0.2} />
                    </div>
                  )}
                      
                        <MDBRow center className="pb-3 m-0">
                          <MDBCol md='12' className="p-0">
                                <div className="d-flex justify-content-center align-items-center pt-3">
                                  <button type="submit" className={!this.state.isPayment ? "rergistration-button modal-button green" : "rergistration-button modal-button black"} ref={this.props.buttonRef}>
                                    <span>Vásárlás</span>
                                  </button>
                                </div>
                          </MDBCol>
                        </MDBRow>
       
                  </div>
              </form>

               
      </>
    );
  }
};

function CouponWithoutLoginFormFunc(props) {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const emailRef = useRef();
  const nameRef = useRef();
  const addressRef = useRef();
  const postcodeRef = useRef();
  const cityRef = useRef();
  const buttonRef = useRef();
  const buttonRef_2 = useRef();
  const colorsRef = useRef(null);
  return <CouponWithoutLoginForm searchParams={searchParams} 
                      navigate={navigate} 
                      hideModal={props.hideModal} 
                      context={props.context} 
                      emailRef={emailRef}
                      nameRef={nameRef}
                      addressRef={addressRef}
                      postcodeRef={postcodeRef}
                      cityRef={cityRef}
                      buttonRef={buttonRef}
                      buttonRef_2={buttonRef_2}
                      colorsRef={colorsRef}
                      />;
}

export default CouponWithoutLoginFormFunc;
