import React, { Component } from "react";
import PaginationComponent from '../../../../PaginationComponent/PaginationComponent.js'


import { MDBContainer,
         MDBTable,
         MDBTableHead,
         MDBTableBody,
         MDBBtn,
         MDBIcon,
         MDBRow,
         MDBCol,
         MDBInput,
         MDBSelect,
         MDBDatepicker
       } from 'mdb-react-ui-kit';


class TableTemplateUser extends Component {

  constructor(props) {
    super(props);
    this.state = {searchText: this.props.searchText !== undefined ? this.props.searchText : ""}
  }

  

  onChangeSearchText = (e) => {
    this.setState({searchText: e.target.value})
    
  }

 

  render(){
    return(
    <div className="table-format-user d-flex">
      <MDBTable align="middle">
       <MDBTableHead>
         <tr>
          <th colSpan={10} scope='col'>
            <MDBRow end className="my-2 d-flex" >
              <MDBInput label='Search' id='form1' type='text' onChange={this.onChangeSearchText} value={this.state.searchText}/>
              
              <MDBBtn type="btn" className="table-search-button" onClick={() => this.props.onSearchtext(this.state.searchText)}>
                <MDBIcon fas icon='search' size="lg"/>
              </MDBBtn>
            </MDBRow>
          </th>
         </tr>
         <tr>
           <th scope='col' style={{width: "12%"}}>
             <MDBRow className="d-flex align-items-center justify-content-start m-0" style={{height: "55px"}}>
               Vezetéknév
             </MDBRow>
           </th>
           <th scope='col' style={{width: "12%"}}>
             <MDBRow className="d-flex align-items-center justify-content-start m-0" style={{height: "55px"}}>
               Keresztnév
             </MDBRow>
           </th>
           <th scope='col' style={{width: "13%"}}>
             <MDBRow className="d-flex align-items-center justify-content-start m-0" style={{height: "55px"}}>
               Email
             </MDBRow>
           </th>
           <th scope='col' style={{width: "13%"}}>
             <MDBRow className="d-flex align-items-center justify-content-start m-0" style={{height: "55px"}}>
               Számlázási név
             </MDBRow>
           </th>
           <th scope='col' style={{width: "10%"}}>
             <MDBRow className="d-flex align-items-center justify-content-start m-0" style={{height: "55px"}}>
               Város
             </MDBRow>
           </th>
           <th scope='col' style={{width: "9%"}}>
             <MDBRow className="d-flex align-items-center justify-content-start m-0" style={{height: "55px"}}>
               Email használata
             </MDBRow>
           </th>
           <th scope='col' style={{width: "9%"}}>
             <MDBRow className="d-flex align-items-center justify-content-center m-0" style={{height: "55px"}}>
               Regisztráció dátuma
             </MDBRow>
           </th>
           <th scope='col' style={{width: "9%"}}>
             <MDBRow className="d-flex align-items-center justify-content-center m-0" style={{height: "55px"}}>
              Lejárat dátuma
             </MDBRow>
           </th>
           <th scope='col' style={{width: "9%"}}>
             <MDBRow className="d-flex align-items-center justify-content-center m-0" style={{height: "55px"}}>
              Státusz
             </MDBRow>
           </th>
           <th scope='col' style={{width: "5%"}}>
             <MDBRow className="d-flex align-items-center justify-content-center m-0" style={{height: "55px"}}>
               Törlés
             </MDBRow>
           </th>
         </tr>
       </MDBTableHead>
       <MDBTableBody>
       {this.props.children}
       </MDBTableBody>
       <tfoot>
         <tr>
           <td colSpan={10} className="text-end">
            <PaginationComponent pageSize={this.props.pageSize}
                                 pageIndex={this.props.pageIndex}
                                 numberOfPages={this.props.numberOfPages}
                                 totalElements={this.props.totalElements}
                                 onChangePageIndex={this.props.onChangePageIndex}
                                 onChangePageSize={this.props.onChangePageSize}/>
           </td>
         </tr>
       </tfoot>
     </MDBTable>
   </div>
    );
  }

}
export default TableTemplateUser;
