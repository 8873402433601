import React, { Component, useContext, useRef  } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';


import AllFAQTable from './TableComponent/FAQ/AllFAQTable.js'

import {
  MDBContainer
} from 'mdb-react-ui-kit';

class FaqPage extends Component {

  constructor(props) {
  	super(props);
    this.state = {};
	}


  render(){
    return (
      <div style={{paddingLeft: "290px"}}>
        <MDBContainer className='pt-4 pt-lg-5'>
          <AllFAQTable />
          <div className="p-5" style={{height: "50px"}}/>
        </MDBContainer>
      </div>
    );
  }

}

export default function FaqPageFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const nodeRef = useRef(null)
    return <FaqPage context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef} />;
}
