import React, { useContext  } from "react";
import { useNavigate } from 'react-router-dom';

import AppContext from '../../AppContext';
import ResetpasswordForm from '../form-components/ResetpasswordForm';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from 'mdb-react-ui-kit';


const PasswordresetModal = (props) => {

const context = useContext(AppContext);

// router hooks
const navigate = useNavigate();

// STATES


/*const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      context.interAxios.post('/auth/google', {token: tokenResponse.access_token}, function(response) {
        context.onSignIn(response.data);
        context.closeLoginModal();
      });
    },
    onError: (error) => alert('Login Failed:', error)
});*/


    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={props.visible}  className="signin-modal">
      <MDBModalDialog centered scrollable>
        <MDBModalContent>
          <MDBModalHeader>
            
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title">Adja meg új jelszavát!</span></MDBModalTitle>
            <ResetpasswordForm />
             
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
export default PasswordresetModal;

