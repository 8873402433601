import React, { useContext, useEffect  } from "react";
import AppContext from '../../AppContext';
import ProfileForm from '../form-components/ProfileForm';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody
} from 'mdb-react-ui-kit';


  const SignUpModal = (props) => {

    const context = useContext(AppContext);

    useEffect(() => {
      setTimeout(() => document.getElementById("signup-modal")?.scrollTo({ top: 0, behavior: 'smooth' }), 100);
     }, []);
    
    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={props.visible}  className="signup-modal" id="signup-modal">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
           
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title">Előfizetés</span></MDBModalTitle>
            <ProfileForm hideModal={props.hideModal} context={context} />
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
export default SignUpModal;
