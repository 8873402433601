import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AppContextProvider from './AppContextProvider';

// LAYOUT, PAGE IMPORTS
import Layout from './components/layout/Layout';
import HomePage from './components/pages/HomePage';
import AtalakitoprogramPage from './components/pages/AtalakitoprogramPage';
import VillamAtalakitoprogramPage from './components/pages/VillamAtalakitoprogramPage';
import ProgramListaPage from './components/pages/ProgramListaPage';
import KapcsolatokPage from './components/pages/KapcsolatokPage';
import NyeremenyPage from './components/pages/NyeremenyPage';
import AdminLoginPage from './components/pages/admin/AdminLoginPage';
import AdminLandingPage from './components/pages/admin/AdminLandingPage';
import FaqPage from './components/pages/admin/FaqPage';
import ProgramPage from './components/pages/admin/ProgramPage';
import CachePage from './components/pages/admin/CachePage';
import NewsletterPage from './components/pages/admin/NewsletterPage';
import ActiveClientPage from './components/pages/admin/ActiveClientPage';
import ClientPage from './components/pages/admin/ClientPage';
import LiveCalendarPage from './components/pages/admin/CalendarComponent/LiveCalendarPage';
import ImageUploadPage from './components/pages/admin/ImageUploadPage';
import VideosDetailPage from './components/pages/VideosDetailPage';
import CategoryDetailPage from './components/pages/CategoryDetailPage';
import VideoPlayPage from './components/pages/VideoPlayPage';
import LiveVideoPlayPage from './components/pages/LiveVideoPlayPage';

function App() {
  return (
    <AppContextProvider>
      <BrowserRouter>
       
          <Routes>
            <Route path="/" element={<Layout/>}>
              <Route path="" element={<HomePage />} />
              <Route path="/fooldal" element={<HomePage />} />
              <Route path="/cookies" element={<HomePage />} />
              <Route path="/nocookies" element={<HomePage />} />
              <Route path="/atalakitoprogram" element={<AtalakitoprogramPage />} />
              <Route path="/villamatalakitoprogram" element={<VillamAtalakitoprogramPage />} />
              <Route path="/programlista" element={<ProgramListaPage />} />
              <Route path="/profile/change-password" element={<HomePage />} />
              <Route path="/kapcsolatok" element={<KapcsolatokPage />} />
              <Route path="/nyeremenyjatek" element={<NyeremenyPage />} />
              <Route path="/etrend" element={<HomePage />} />
              <Route path="/sikeresfizetes" element={<HomePage />} />
              <Route path="/sikereskuponvasarlas" element={<HomePage />} />
              <Route path="/sikertelenfizetes" element={<HomePage />} />
              <Route path="/paypal-siker" element={<HomePage />} />
              <Route path="/paypal-megsem" element={<HomePage />} />
              <Route path="/megszakitva" element={<HomePage />} />
              <Route path="/visszalepes" element={<HomePage />} />
              <Route path="/idotullepes" element={<HomePage />} />
              <Route path="/videoplay" element={<VideoPlayPage />} />
              <Route path="/livevideoplay" element={<LiveVideoPlayPage />} />
              <Route path="/videodetail" element={<VideosDetailPage />} />
              <Route path="/categorydetail" element={<CategoryDetailPage />} />
            </Route>
              <Route path="/admin" element={<AdminLandingPage/>}>
              <Route path="faq/*" element={<FaqPage/>}/>
              <Route path="programok/*" element={<ProgramPage/>}/>
              <Route path="cache/*" element={<CachePage/>}/>
              <Route path="newsletter/*" element={<NewsletterPage/>}/>
              <Route path="activeclient/*" element={<ActiveClientPage/>}/>
              <Route path="inactiveclient/*" element={<ClientPage/>}/>
              <Route path="livecalendar/*" element={<LiveCalendarPage/>}/>
              <Route path="imageupload/*" element={<ImageUploadPage/>}/>
            </Route>

  <Route path="admin/login" element={<AdminLoginPage/>}/>
          </Routes>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;
