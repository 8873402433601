// react imports
import React, { useContext, useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import prev from '../../assets/media/icons/prev.png'
import AppContext from '../../AppContext';

import { MDBContainer
          } from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const LiveVideoPlayPage = (props) => {

  const location = useLocation();

  // react router navigation
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);
  const [videoHeight, setVideoHeight] = useState(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
  const [isVideoHover, setIsVideoHover] = useState(false);

  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
    setVideoHeight(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
 
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);


  useEffect(() => {
    setTimeout(() => {
      setIsVideoHover(true)}, 500)
  }, []);


  return (
    <MDBContainer fluid className="videoplay-container">
      <button type="button" onClick={() => {
                              navigate('/fooldal')
                            }}><img src={prev} alt="back"/><span>Back</span></button>
      <section className="first-section" id="">
        <div className={isVideoHover ? "reactplayer-div hover-effect" : "reactplayer-div"}>
          <iframe src={context.getState()?.newLiveVideo?.videoUrl +"&autoplay=1"} 
              width={videoHeight < videoWidht*0.5625 ? videoHeight/0.5625 :  videoWidht} 
              height={videoHeight < videoWidht*0.5625 ? videoHeight : videoWidht*0.5625 +"px"} 
              allowFullScreen="allowFullScreen" 
              allow="autoplay"
              mozallowfullscreen="mozallowfullscreen" 
              msallowfullscreen="msallowfullscreen" 
              oallowfullscreen="oallowfullscreen" 
              webkitallowfullscreen="webkitallowfullscreen" ></iframe>
          <iframe src={context.getState()?.newLiveVideo?.interactionUrl} 
              width="300px" 
              height={videoHeight < videoWidht*0.5625 ? videoHeight : videoWidht*0.5625 +"px"} 
              frameBorder="0" 
              allowFullScreen="allowFullScreen"
              mozallowfullscreen="mozallowfullscreen" 
              msallowfullscreen="msallowfullscreen" 
              oallowfullscreen="oallowfullscreen" 
              webkitallowfullscreen="webkitallowfullscreen"></iframe>
                
        </div>
       
      </section>
    </MDBContainer>


  );
};

export default LiveVideoPlayPage;
