import React, { useContext } from "react";
import axios from "axios";
import FacebookLogin from "@greatsumini/react-facebook-login";
import AppleSignin from "react-apple-signin-auth";

import loginAppleIcon from "../../assets/media/images/login-modal/apple-icon.svg";
import loginFacebookIcon from "../../assets/media/images/login-modal/facebook-icon.svg";
import loginGoogleIcon from "../../assets/media/images/login-modal/google-icon.svg";

import { FacebookAppId, AppleLogin } from "../../data/loginkeys";

import AppContext from "../../AppContext";
import SignInForm from "../form-components/SignInForm";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";

const SignInModal = (props) => {
  const context = useContext(AppContext);

  // STATES

  /*const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
          context.interAxios.post('/auth/google', {token: tokenResponse.access_token}, function(response) {
            context.onSignIn(response.data);
            context.closeLoginModal();
          });
        },
        onError: (error) => alert('Login Failed:', error)
    });*/

  return (
    <MDBModal
      staticBackdrop
      tabIndex="-1"
      closeOnEsc={false}
      show={props.visible}
      className="signin-modal"
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={props.hideModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBModalTitle>
              <span className="modal-title">Bejelentkezés</span>
            </MDBModalTitle>
            <SignInForm
              hideModal={props.hideModal}
              loginEvent={props.loginEvent}
            />
            {/*} <div className="social-icons">
                <AppleSignin
                  authOptions={{
                    clientId: AppleLogin.clientId,
                    scope: AppleLogin.scope,
                    redirectURI: AppleLogin.redirectURI,
                    state: AppleLogin.state,
                    nonce: AppleLogin.nonce,
                    usePopup: AppleLogin.usePopup,
                  }}
                  uiType="dark"
                  className="apple-auth-btn"
                  noDefaultStyle={false}
                  buttonExtraChildren="Continue with Apple"
                  onSuccess={(response) => {
                    axios.post(backendUrl() +'/auth/apple', { 'token': response.authorization.id_token, 'code': response.authorization.code }, { headers: { 'Content-type': 'application/json'}}).then(function(response) {
                        context.onSignIn(response.data);
                        context.closeLoginModal();
                    })
                  }}
                  onError={(error) => console.error(error)}
                  skipScript={false}
                  render={(props) => <button {...props}> <img src={loginAppleIcon} alt="Apple Icon" /></button>}
                />

                <button >
                  <img src={loginGoogleIcon} alt="Google Icon" />
                </button> 
          
                <FacebookLogin
                    appId= {FacebookAppId.appId}
                    style={{
                      backgroundColor: 'transparent',
                      color: '#fff',
                      fontSize: '17px',
                      padding: 0,
                      border: "none",
                      borderRadius: "15px",
                    }}
                    children={<img src={loginFacebookIcon} alt="Facebook Icon" />
                    } 
                    onSuccess={(response) => {
                        context.interAxios.post('/auth/facebook', {token: response.accessToken}, function(response) {
                          context.onSignIn(response.data);
                          context.closeLoginModal();
                        });
                    }}
                    onFail={(error) => {
                        console.log('Login Failed!', error);
                    }}
                    onProfileSuccess={(response) => {
                        console.log(response);
                    }}
                />
                  </div>*/}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
export default SignInModal;
