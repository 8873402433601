// REACT IMPORTS
import React, { useContext, useState } from "react";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// MDBOOTSTRAP IMPORTS
import { MDBRow, MDBCol, MDBInput, MDBBtn } from "mdb-react-ui-kit";

// CUSTOM COMPONENT IMPORTS
import AppContext from '../../../AppContext';

//images
import logo from '../../../assets/media/logo/logo-2.png'
import email_icon from '../../../assets/media/icons/email_icon.svg'
import aszf from '../../../static/jogi/aszf.pdf'
import privacy from '../../../static/jogi/privacy.pdf'
import jatek from '../../../static/jogi/jatekszabalyzat.pdf'

const Footer = (props) => {
  const context = useContext(AppContext);

  const [email, setEmail] = useState("");

  const onGetAllNewsletter = () => {
    let self = this;
    if(email !== "") {
      context.interAxios.post('/newsletter', {email: email}, function(response) {
        setEmail("");
        context.openLoginModal("hirlevel");
      });
    }else {
      alert("Add meg az emailcímedet!")
      
    }
  }
  
  return (
    <footer className="alakreform-footer">
      <div className="d-flex align-items-center footer-top" id="footer">
        <MDBRow style={{height:"100%", width: "100%"}} className="">
          <MDBCol  md="6" sm="12" size="12" lg="5" center className="p-0 footer-col-1">
            <img src={logo}/>
          </MDBCol>
         {/* <MDBCol md="4" sm="6" size="12" lg="2" center className="p-0 footer-col-2">
            <div className="coulom_1">
            {context.isLoggedIn() && (<>
              <MDBRow>
                <span className="profil-title">Profil</span>
              </MDBRow>
              <MDBRow>
                <button className="rergistration-button  text-button-gyik" onClick={() => context.openLoginModal("editprofile")}>
                  <span className="profil-text">Fiókom</span>
                </button>
            </MDBRow></>)}
            </div>
            </MDBCol>*/}
          <MDBCol  md="6" sm="12" size="12" lg="3" center className="p-0 footer-col-3">
            <div className="coulom_2">
              <MDBRow>
                {/*<span className="help-title">Help center</span>*/}
              </MDBRow>
              {context.isLoggedIn() && (
              <MDBRow className="pb-2">
                <button className="rergistration-button  text-button-gyik" onClick={() => context.openLoginModal("gyik")}>
                  <span className="help-text">Gyakori kérdések</span>
                </button>
                
              </MDBRow>)}
              <MDBRow className="pb-2">
              <a href={aszf} target="_blank">
                <span className="help-text">ASZF</span>
              </a>
              </MDBRow>
              <MDBRow className="pb-2">
                <a href={privacy} target="_blank">
                  <span className="help-text">Adatvédelmi nyilatkozat</span>
                </a>
              </MDBRow>
             {/* <MDBRow className="pb-2">
                <a href={jatek} target="_blank">
                  <span className="help-text">Játékszabályzat</span>
                </a>
              </MDBRow>*/}
            </div>
          </MDBCol>
          <MDBCol  md="12" sm="12" size="12" lg="4" center className="p-0 footer-col-4">
            <div className="coulom_3">
              <div>
                <span className="iratkozzfel-title">Iratkozz fel hírlevelünkre!</span>
              </div>
              <div>
                
                <span className="iratkozzfel-text">Ne mulassz el semmit!</span>
              </div>
                <div className="email-form">
                <MDBInput value={email}
                          name='email'
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          label='Email'/>
                <img src={email_icon} alt="email" className="email-img"/>
                <MDBBtn type="btn" className="email-button" onClick={() => onGetAllNewsletter()}>
                  Feliratkozás
                </MDBBtn>
              </div>
            </div>
           
          </MDBCol>
        </MDBRow>
      </div>
      <div className="copyright">
        <span>Copyright © rubintreka.hu</span>
      </div>
      
    </footer>
  );
};

export default (Footer);
