// react imports
import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import elso_het_menu from '../../assets/media/images/RRmenu 1 het.jpg'
import elso_het_edzesterv from '../../assets/media/images/RRedzesterv 1het.jpg'
import masodik_het_menu from '../../assets/media/images/RRmenu 2 het.jpg'
import masodik_het_edzesterv from '../../assets/media/images/RRedzesterv 2het.jpg'
import harmadik_het_menu from '../../assets/media/images/RRmenu 3 het.jpg'
import harmadik_het_edzesterv from '../../assets/media/images/RRedzesterv 3het.jpg'
import negyedik_het_menu from '../../assets/media/images/RRmenu 4 het.jpg'
import negyedik_het_edzesterv from '../../assets/media/images/RRedzesterv 4het.jpg'
import villam_elso_het_menu from '../../assets/media/images/etrend1.jpg'
import villam_elso_het_edzesterv from '../../assets/media/images/edzes1.jpg'

// CUSTOM COMPONENTS
import AppContext from '../../AppContext';
import Cookies from 'universal-cookie';
import { MDBContainer } from "mdb-react-ui-kit";
const cookies = new Cookies();

const AtalakitoprogramPage = (props) => {


  // REACT ROUTER PARAMS - for processing url
  const [searchParams] = useSearchParams();
  const context = useContext(AppContext);
  

  // STATES
  const [isCard, setIsCard] = useState(true);
  
  
  return (
    <div>
        <section className={context.getState().liveTime !== undefined  ? "atalakitoprogram-section live" : "atalakitoprogram-section"}>
            <MDBContainer fluid style={{maxWidth: "1170px"}}>
                {/*<div className="title">
                    <p>Villám átalakító program</p>
                </div>
                
                <div className="menu">
                    <span>1. heti étrend</span>
                    <img src={villam_elso_het_menu} alt="elso_het_menu"/>
                </div>
                <div className="edzesterv">
                    <span>1. heti edzésterv</span>
                    <img src={villam_elso_het_edzesterv} alt="elso_het_menu"/>
                </div>*/}
                <div className="title">
                    <p>Átalakító program</p>
                </div>
                <div className="menu">
                    <span>1. heti étrend</span>
                    <img src={elso_het_menu} alt="elso_het_menu"/>
                </div>
                <div className="edzesterv">
                    <span>1. heti edzésterv</span>
                    <img src={elso_het_edzesterv} alt="elso_het_menu"/>
                </div>
                <div className="menu pt-5">
                    <span>2. heti étrend</span>
                    <img src={masodik_het_menu} alt="elso_het_menu"/>
                </div>
                <div className="edzesterv">
                    <span>2. heti edzésterv</span>
                    <img src={masodik_het_edzesterv} alt="elso_het_menu"/>
                </div>
                <div className="menu pt-5">
                    <span>3. heti étrend</span>
                    <img src={harmadik_het_menu} alt="elso_het_menu"/>
                </div>
                <div className="edzesterv">
                    <span>3. heti edzésterv</span>
                    <img src={harmadik_het_edzesterv} alt="elso_het_menu"/>
                </div>
                <div className="menu pt-5">
                    <span>4. heti étrend</span>
                    <img src={negyedik_het_menu} alt="elso_het_menu"/>
                </div>
                <div className="edzesterv">
                    <span>4. heti edzésterv</span>
                    <img src={negyedik_het_edzesterv} alt="elso_het_menu"/>
  </div>
            </MDBContainer>
        
        </section>
   
        
       
        
    </div>
  );
};

export default AtalakitoprogramPage;
