// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import ImageUploader from './image-uploader/ImageUploader';
import SingleImageUploader from './image-uploader/SingleImageUploader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBDatepicker,
  MDBContainer,
  MDBCard,
  MDBSelect 
} from 'mdb-react-ui-kit';


// STYLE IMPORTS
import "react-toggle/style.css";
const customWeekdaysNarrow = ['V', 'H', 'K', 'Sz', 'Cs', 'P', 'Sz'];
const customMonthsFull = [ 'Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December', ]

class CacheForm extends Component {
 
  constructor(props) {
    super(props);
    this.state = {email: ""};
    };

    onCustomer = (e) => {
      e.preventDefault();
        let self = this;
      if(this.state.email !== ""){
        this.props.context.interAxios.delete('/admin/cache/user?email=' + this.state.email,
        function(response) {
          self.setState({
            email: ""
          })
        });
      }
       
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

  
  render() {
    return (<div>
    <MDBContainer className='pt-4 pt-lg-5 px-0'>
      <MDBCard style={{ width: '100%', padding: "25px"}}>
              <form onSubmit={(e) => this.onCustomer(e)} className="profile-form" autoComplete="off">
                <div className="white-panel">
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='4' className="px-0">
                      <label htmlFor="title">Email <span>*</span></label>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                      />
                    </MDBCol>
                  </MDBRow>

                 
                  <MDBRow className="pb-5 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start px-0 pb-5" >
                          <div>
                            <button type="submit" className="rergistration-button green" >
                            <span>Cache törlése</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>
           
                
                  </div>
              </form>
      </MDBCard>
      <div className="p-5" style={{height: "50px"}}/>
    </MDBContainer>
      </div>
    );
  }
};

function CacheFormFunc(props) {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const utalasRef = useRef();
  const emailRef = useRef();
  const phonenumberRef = useRef();
  const addressRef = useRef();
  const cityRef = useRef();
  const zipCodeRef = useRef();
  const birthPlaceRef = useRef();
  const birthDateRef = useRef();
  const genderRef = useRef();
  const anyjaneveRef = useRef();
  const szuletesinevRef = useRef();
  const nameRef = useRef()
  return <CacheForm searchParams={searchParams} 
                      navigate={navigate} 
                      context={props.context} 
                      utalasRef={utalasRef}
                      emailRef={emailRef}
                      phonenumberRef={phonenumberRef}
                      addressRef={addressRef}
                      cityRef={cityRef}
                      zipCodeRef={zipCodeRef}
                      birthPlaceRef={birthPlaceRef}
                      birthDateRef={birthDateRef}
                      genderRef={genderRef}
                      anyjaneveRef={anyjaneveRef}
                      szuletesinevRef={szuletesinevRef}
                      nameRef={nameRef}
                      onGetAllProgram={props.onGetAllProgram}
                      onCloseNewProgram={props.onCloseNewProgram}
                      />;
}

export default CacheFormFunc;
