import React, { useContext  } from "react";
import { useNavigate } from 'react-router-dom';

import AppContext from '../../AppContext';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from 'mdb-react-ui-kit';


const ElofizetekModal = (props) => {

const context = useContext(AppContext);

// router hooks
const navigate = useNavigate();

// STATES


/*const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      context.interAxios.post('/auth/google', {token: tokenResponse.access_token}, function(response) {
        context.onSignIn(response.data);
        context.closeLoginModal();
      });
    },
    onError: (error) => alert('Login Failed:', error)
});*/


    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={props.visible}  className="signin-modal">
      <MDBModalDialog  centered scrollable>
        <MDBModalContent>
          <MDBModalHeader>
            
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title">A videótár teljes tartalmát csak sikeres fizetés után láthatod! </span></MDBModalTitle>
              <span className="modal-description">Hosszabbítsd meg az előfizetésedet vagy regisztrálj az oldalon és minden tartalmat elérhetsz! </span>
              <div className="justify-content-center d-flex align-ites-center pt-5" >
                <button type="button" className="rergistration-button modal-button green" onClick={() => {props.hideModal(); navigate('/fooldal'); setTimeout(() => {
            context.openLoginModal("signup")
          }, 500)}} >
                <span>Előfizetek</span>
                </button>
              </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
export default ElofizetekModal;
