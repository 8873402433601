

import React, { useContext  } from "react";

import AppContext from '../../AppContext';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from 'mdb-react-ui-kit';


const FirstWarningModal = (props) => {

const context = useContext(AppContext);

    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={props.visible}  className="altalanos-modal">
      <MDBModalDialog scrollable centered>
        <MDBModalContent>
          <MDBModalHeader>
            
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title">Üdvözlünk!</span></MDBModalTitle>
              
                <span className="modal-description">Sajnos nem sikerült még az előfizetésed aktiválása.</span>
             
              
              <br/><span className="modal-description">Kérjük ellenőrizd a számlázási és a bankkártya adataidat, 
                                          hogy zavartalanul használhasd a 
                                          folyamatosan bővülő és fejlődő videótárunkat!</span>
                                          <br/><br/><span className="modal-description">Ezt 24 órán belül szükséges megtenned, 
                                                        ezután a rendszer automatikusan
                                                        korlátozza az elérhető tartalmakat.</span>
         
            <div className="d-flex justify-content-center align-items-center pt-5">
                <button className="rergistration-button  green" onClick={() => {props.hideModal(); setTimeout(() => {
                              context.openLoginModal("editprofile")
                            }, 500)}} >
                      <span>Irány a Profil!</span>
                  </button>

                  <button
                    className="rergistration-button  white"
                    onClick={props.hideModal}
                  >
                    <span>Mégsem</span>
                  </button>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default FirstWarningModal;