import React, { Component  } from "react";

import { MDBBtn,
         MDBIcon,
         MDBRow,
         MDBCol,
         MDBSelect
       } from 'mdb-react-ui-kit';

const data = [ {text: "10", value: 10},
               {text: "25", value: 25}]
class PaginationComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {upButton: true,
                  downButton: false,
                  pageSize: this.props.pageSize,
                  pageIndex: this.props.pageIndex,
                  numberOfPages: this.props.numberOfPages,
                  totalElements: this.props.totalElements
                }
  }

  componentDidMount() {
  }

  componentDidUpdate(prewProps, prewState) {

  }


  toCountProSite = (e) => {
    this.setState({pageSize: e, pageIndex: 0});
    this.props.onChangePageSize(e, 0);
  }

  onIncrementalPagination = () => {
    this.setState({upButton: false, downButton: true})
    if(this.state.pageIndex < this.props.numberOfPages - 2){
      this.setState({pageIndex: this.props.pageIndex + 1, upButton: true})
      this.props.onChangePageIndex(this.state.pageIndex + 1);
    }else{
        this.setState({pageIndex: this.state.pageIndex + 1, upButton: false});
        this.props.onChangePageIndex(this.state.pageIndex + 1);
    }
  }

  onDescendingPagination = () => {
    this.setState({downButton: false, upButton: true})
    if(this.state.pageIndex > 1){
      this.setState({pageIndex: this.state.pageIndex - 1, downButton: true})
      this.props.onChangePageIndex(this.state.pageIndex - 1);
    }else{
        this.setState({pageIndex: this.state.pageIndex - 1, downButton: false})
        this.props.onChangePageIndex(this.state.pageIndex - 1);
    }
  }

  onNothing = () => {}

  render(){
    let firstNumber = this.props.pageIndex * this.props.pageSize + 1;
    let lastNumber = (this.props.pageIndex+1) * this.props.pageSize < this.props.totalElements ? (this.props.pageIndex+1) * this.props.pageSize : this.props.totalElements;

    return(
    <div className="pagination-format d-flex">
    <MDBRow end className="m-0 d-flex" style={{width: "100%"}}>
    <MDBCol className="d-flex align-items-center justify-content-end" md="auto">
    <div className="proba">
      <MDBSelect size='lg' id="filter-select-footer" onValueChange={(e) => this.toCountProSite(e.value)}
        data={data}
      />
    </div>
    </MDBCol>
    <MDBCol className="d-flex align-items-center justify-content-center" md="auto">

      <span>{this.props.totalElements === 0 ? "0 összesen " + this.props.totalElements : firstNumber + " - " + lastNumber + " összesen " + (this.props.totalElements + 21) }</span>
    </MDBCol>
    <MDBCol className="d-flex align-items-center justify-content-center" md="auto">
      <MDBBtn type="btn"
              className="table-icon-btn"
              onClick={this.state.downButton ? this.onDescendingPagination : this.onNothing}>
        <MDBIcon fas icon="angle-left" color={this.state.downButton ? "black-50": "white"}/>
      </MDBBtn>
    </MDBCol>
    <MDBCol className="d-flex align-items-center justify-content-center" md="auto">
      <MDBBtn type="btn"
              className="table-icon-btn"
              onClick={lastNumber < this.props.totalElements ? this.onIncrementalPagination : this.onNothing}>
        <MDBIcon fas icon="angle-right" color={lastNumber < this.props.totalElements ? "black-50": "white"}/>
      </MDBBtn>
    </MDBCol>
    </MDBRow>
    </div>
    );
  }

}
export default PaginationComponent;
