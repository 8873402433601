import React, { Component, useContext  } from "react";
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../../../AppContext';

import DeleteModal from '../../Modals/DeleteModal.js';
import FaqEditModal from '../../Modals/FaqEditModal.js';

import { MDBContainer,
         MDBBtn,
         MDBIcon,
         MDBSwitch,
         MDBDatatable,
         MDBAlert,
         MDBRow,
         MDBCol
       } from 'mdb-react-ui-kit';


class ProgramokTable extends Component {

  constructor(props) {
    super(props);
    this.state = {programList:[]}
  }

  componentDidMount() {
    //this.props.onGetAllProgram();
  }

  

  onGetAllProgram = () => {
    let self = this;
    this.props.context.interAxios.get('/camp', function(response) {
      self.setState({programList: response.data});
      console.log(response.data)
    });
  }

  onCreateNewProgram = (question, answer, weight) => {
    let self = this;
    this.props.context.interAxios.post('/admin/camp',{
                                                  weight: weight-1,
                                                  question: question,
                                                  answer: answer,
                                                  active: false
                                              }, function(response) {
      self.onGetAllProgram();
    });
  }

  
  onDeleteProgramById = (id) => {
    console.log(id)
    this.setState({alert: true, alertText:"A programot sikeresen kitörölte!"})
    let self = this;
    this.props.context.interAxios.delete('/admin/camp/' + id, function(response) {
      self.onGetAllProgram();
    });
  }


  showDeleteProgramByIdModal = (id) => {
    this.setState({programId: id, showDeleteModal: true, status: "Program törlése", modalText: "Valóban törölni szeretné a programot?"})
  }

  hideDeleteProgramByIdModal = () => {
    this.setState({programId: "", showDeleteModal: false})
  }

 
  render(){
    let self = this;
    let actionData = {
    columns: [
      { label: 'Sorszám', field: 'weight' },
      { label: 'Program neve', field: 'name' },
      { label: 'Lehetőségek', field: 'aktionen', sort: false },
    ],
    rows: this.props.programList.map((row, n) => {
      return {
        weight: (
          <div className="d-flex align-items-center justify-content-center" style={{height: "100%"}}> {n+1} </div>),
        name: (<div> {row.title}</div>),
        aktionen: (
          <div className="d-flex align-items-center justify-content-center" style={{height: "100%"}}> 
          <MDBBtn className='fw-bold' color='link' size='sm' rippleColor='dark' onClick={() => this.showDeleteProgramByIdModal(row.id)}>
            <MDBIcon fas icon="trash-alt" color="danger"/>
          </MDBBtn>
          </div>
        ),
      };
    }),
  };
    return(
      <MDBContainer fluid className="p-0">
        <div style={{width: "50%"}}>
        <MDBRow className="pt-5 py-3" >
          <MDBCol md="6" className="d-flex align-items-center justify-content-start">
          <h1 className='m-0'>Program lista</h1>
          </MDBCol>
          <MDBCol md="6" className="d-flex align-items-center justify-content-end">
          <MDBBtn type="btn" className="add-new" onClick={this.props.onNewProgram}>
            Új Program létrehozása
          </MDBBtn>
          </MDBCol>
        </MDBRow>
        <section>

          <div className='shadow-4 rounded-5 overflow-hidden faq-table'>
            <MDBDatatable
                      hover
                      data={actionData}
                      ofText='összesen'
                      rowsText='Megjelenítés oldalanként'
                      allText='Összes'
                      format={(field) => {
                        if(field==='weight') {
                          return {width: "125px"}
                        }
                     
                        if(field==='aktionen') {
                          return {width: "130px"}
                        }
                      }
                      }
            />
          </div>
        </section>
        </div>
        <MDBAlert
          show={this.state.alert}
          color='primary'
          autohide
          position='top-center'
          offset={50}
          delay={3000}
          appendToBody
          onClosed={() => { self.setState({alert: false, alertText: ""})}}
        >{this.state.alertText}</MDBAlert>
      {this.state.showDeleteModal ?
       <DeleteModal hideModal={this.hideDeleteProgramByIdModal}
                    showModal={this.state.showDeleteModal}
                    modalTyp={this.state.status}
                    faqId={this.state.programId}
                    onDeleteFAQById ={this.onDeleteProgramById}
                    modalText={this.state.modalText}
                    /> : false}
      </MDBContainer>
    );
  }

}
export default function ProgramokTableFunc(props) {
    const context = useContext(AppContext);
    let location = useLocation();
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    return <ProgramokTable context={context}
                        searchParams={searchParams}
                        navigate={navigate}
                        location={location}
                        onNewProgram={props.onNewProgram}
                        onGetAllProgram={props.onGetAllProgram} 
                        programList={props.programList}/>;
}
