// react imports
import React, { useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import clock from '../../assets/media/icons/clock.png'
import gymflix_logo from '../../assets/media/logo/logo.png'
import video_play from '../../assets/media/icons/video-play.png'
import video_clock from '../../assets/media/icons/video-clock.png'
// CUSTOM COMPONENTS
import AppContext from '../../AppContext';
import { MDBRow,
         MDBCol
          } from 'mdb-react-ui-kit';

const CategoryComponent = (props) => {

    const labelRef = useRef()
    

  // REACT ROUTER PARAMS - for processing url
  const context = useContext(AppContext);
  const navigate = useNavigate();


  // STATES
  const [videocardHeight, setVideocardHeight] = useState(document.getElementById("thumbnail-image")?.clientWidth*9/16);

  const truncateString = (str, num) => {
    
    if (str.length <= num) {
      
        return str
      }
          return str.slice(0, num) + '...'
  }

  return (
    <div ref={labelRef} className="d-flex justify-content-center align-items-center">
        <MDBRow style={{maxWidth: "1258px", width: "100%"}} className="m-0">
            {props.videoList.map((k,n) => {
                if(k.name.split("_").length > 1){
                    var title_date = k.name.split("_")[0];
                    var title = k.name.substr(title_date.length + 1);
                }else {
                    var title_date = "";
                    var title = k.name;
                }
                return(
                    <MDBCol md="4" lg="3" xl="3" sm="6" size="12" key={n} className="pb-4">
                        <div className="videocard-more" key={n} id="touchsurface" style={{height: videocardHeight}} onClick={() => { navigate('/videodetail', { state: { video: k, } })}}>
                            <div className="background-img">
                                <img src={k.pictureUrl} alt="thumbnail-image" id="thumbnail-image" className="thumbnail-image"/>
                                <div className="timer">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img src={clock}/>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <span>{parseInt(k.duration/60)}</span>
                                    </div>
                                </div>
                                {props.category === "Legújabb feltöltések" && k.isNew && (
                                    <div className="new-text">
                                        <span>NEW</span>
                                    </div>
                                )}
                                
                                <div className="video-card-layout d-flex justify-content-start align-items-end">
                                    <div className={context.isLoggedIn() ? "text-position" : "text-position logout"}>
                                        <span className={k.category === "Teljes test edzés" ? " title green" : k.category === "Páros edzés" ? "title pink" : "title purple"}>
                                            {title}
                                        </span>
                                    </div>
                                    <img src={gymflix_logo} alt="gymflix" id="gymflix" className="gymflix-logo"/>
                                </div>
                                
                                <div className="description-hover">
                                
                                <button className="play-button" type="button" ><img src={video_play} alt="player"/></button>

                                <div className="time">
                                    <img src={video_clock} alt="timer"/>
                                    <span>{parseInt(k.duration/60) + "minutes"}</span>
                                </div>
                                
                                <div className="bookmark-div">
                                    <div className="more-text">
                                        <span className="title-2">{truncateString(k.name, 40)}</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </MDBCol>)
            })}
        </MDBRow>
    </div>
  );
};

export default CategoryComponent;
