// react imports
import React, { useContext, useState, useRef, useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Swiper from 'swiper';
import { FreeMode } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';

import video_clock from '../../assets/media/icons/video-clock.png'
import clock from '../../assets/media/icons/clock.png'
import video_play from '../../assets/media/icons/video-play.png'
import bookmark_filled from '../../assets/media/icons/bookmark-filled.png'
import bookmark_empty from '../../assets/media/icons/bookmark-empty.png'
import gymflix_logo from '../../assets/media/logo/logo.png'
// CUSTOM COMPONENTS
import AppContext from '../../AppContext';
import { Fotolist } from '../../data/coverFotok';
import { MDBIcon,
  MDBTooltip, MDBLoadingManagement
   } from 'mdb-react-ui-kit';

const CarouselComponent = (props) => {

  //MDBAlert

  // REACT ROUTER PARAMS - for processing url
  const context = useContext(AppContext);

  // STATES
  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);
  const [starttime, setStarttime] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [videocardHeight, setVideocardHeight] = useState(document.getElementById("thumbnail-image")?.clientWidth*9/16);

   // react router navigation
   const navigate = useNavigate();


  const responsive = {
    desktop2: {
      breakpoint: { max: 4000, min: 1920 },
      items:8,
      partialVisibilityGutter: 3
    },
    desktop: {
      breakpoint: { max: 1920, min: 1400},
      items: 5,
      partialVisibilityGutter: 3
    },
    tablet2: {
      breakpoint: { max: 1400, min: 1100 },
      items: 4,
      partialVisibilityGutter: 3
    },
    tablet: {
      breakpoint: { max: 1100, min: 740 },
      items: 3,
      partialVisibilityGutter: 3
    },
    small: {
        breakpoint: { max: 450, min: 0 },
        items: 2,
        partialVisibilityGutter: 1
    }
  };

  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
    setVideocardHeight(document.getElementById("thumbnail-image")?.clientWidth*9/16)
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    setTimeout(() => {setStarttime(true) }, 300)
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setVideocardHeight(document.getElementById("thumbnail-image")?.clientWidth*9/16)
  }, [document.getElementById("thumbnail-image")?.clientWidth]);
 


  const truncateString = (str, num) => {
    
    if (str.length <= num) {
      
        return str
      }
          return str.slice(0, num) + '...'
  }

  const swiper = new Swiper('.swiper', {
    // Optional parameters
    modules: [FreeMode],
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      // when window width is >= 480px
      600: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      // when window width is >= 640px
      760: {
        slidesPerView: 5,
        spaceBetween: 0
      }
    },
    direction: 'horizontal',
    loop: false,
    effect: 'slide',
    freeMode: {
      enabled: true,
      sticky: true,
    },
  });

 
  return (
    <div className="more-carousel" style={{height: videocardHeight + 50}}>
      <div className="d-none d-lg-block">
        <Carousel
                additionalTransfrom={0} 
                swipeable={true}
                draggable={!window.matchMedia('(max-device-width: 740px)').matches ? false :  true}
                showDots={!window.matchMedia('(max-device-width: 740px)').matches ? true :  false}
                responsive={responsive}
                infinite={true}
                renderArrowsWhenDisabled={true}
                renderDotsOutside={true}
                slidesToSlide={4}
                centerMode={false}
                keyBoardControl={true}
                customTransition={window.matchMedia('(max-device-width: 400px)').matches ? "transform 100ms ease-in-out" : "transform 1000ms ease-in-out"}
                transitionDuration={window.matchMedia('(max-device-width: 400px)').matches ? 100 : 1000}
                containerClass={isFirstStep ? "carousel-container firststep" : "carousel-container"}
                removeArrowOnDeviceType={["small", "mobileclaim"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                afterChange={(previousSlide, { currentSlide, onMove }) => {
                    setIsFirstStep(false);
                   
                }}
        >
          {props.videoList.filter(k => k.categories.includes(props.category) || props.category === "Legújabb feltöltések" || props.category === "Kedvencek").map((k,n) => {
              let array = k.name.split(" ");
              if(k.name.split("dátum:").length > 1){
                var title_date = k.name.split(" ")[0];
                var title_date_final = title_date.split(":")[1];
                var title = k.name.substr(title_date.length + 1);
            }else {
                var title_date = "";
                var title = k.name;
            }
              return(
                  <div className="videocard-more" key={n} id="touchsurface" style={{height: videocardHeight}}>
                      <div className="background-img">
                        <img src={k.pictureUrl} alt="thumbnail-image" id="thumbnail-image" className="thumbnail-image"/>
                        <div className="timer">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={clock}/>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <span>{parseInt(k.duration/60)}</span>
                            </div>
                        </div>
                        
                        
                        {props.category === "Legújabb feltöltések" && k.isNew && (
                            <div className="new-text">
                                <span>NEW</span>
                            </div>
                        )}
                        
                        <div className="video-card-layout d-flex justify-content-start align-items-end">
                            <div className="text-position">
                                <span className={k.category === "Teljes test edzés" ? " title green" : k.category === "Páros edzés" ? "title pink" : "title purple"}>
                                    {title}
                                </span>
                            </div>
                            <img src={gymflix_logo} alt="gymflix" id="gymflix" className="gymflix-logo"/>
                        </div>
                        
                        <div className="description-hover">
                         
                          <button className="play-button" type="button" onClick={() => { navigate('/videodetail', { state: { video: k, } })}}><img src={video_play} alt="player"/></button>

                          <div className="time">
                              <img src={video_clock} alt="timer"/>
                              <span>{parseInt(k.duration/60) + "minutes"}</span>
                          </div>
                          
                          <div className="bookmark-div">
                            <div className="more-text">
                                <span className="title-2">{truncateString(k.name, 40)}</span>
                            </div>
                            {props.category === "Kedvencek" ? 
                              <button className="bookmark-button" type="button" onClick={() => {props.onDeleteVideoFromBookmark(k.id)}}>
                                <img src={bookmark_filled} alt="bookmark" />
                              </button>:
                              <button className="bookmark-button" type="button" onClick={k.bookmarked ? 
                                () => {props.onDeleteVideoFromBookmark(k.id)} : () => {props.onPutVideoToBookmark(k.id)}}>
                                <img src={k.bookmarked ? bookmark_filled : bookmark_empty} alt="bookmark" />
                              </button>}
                           
                          </div>
                        </div>
                      </div>
                  </div>
          )})}
        </Carousel>
      </div>
      <div className="swiper d-block d-lg-none">
        <div className="swiper-wrapper">
        {props.videoList.filter(k => k.categories.includes(props.category) || props.category === "Legújabb feltöltések" || props.category === "Kedvencek").map((k,n) => {  
            return(
              <>
              {starttime && (
                <div className="swiper-slide" key={n}>
                <div className="videocard-more-respo">
                    <div className="background-img" onClick={() => { navigate('/videodetail', { state: { video: k } })}}>
                      <img src={k.pictureUrl} alt="thumbnail-image"/>
                      <div className="more-text">
                        <span>{truncateString(k.name, 20)}</span>
                      </div>
                      <div style={{position: "absolute", width: "100%", height: "30%",
                                     background:  "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 44.99%, rgba(0,0,0,0.8) 71.68%, rgba(0,0,0,0.92) 100%, #000000 100%)",
                                     bottom: "0px",
                                     
                                    }}/>
                    </div>
                </div>
                </div>
              )}
              </>
            )
          })}
        </div>
      </div>
    </div>


  );
};

export default CarouselComponent;
