import React, { Component, useContext } from "react";
import{
  MDBTimepicker,
  MDBInput,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';

import AppContext from '../../../../AppContext';
import GenericModal from './GenericModal.js';

import moment from 'moment'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class LiveEventModal extends Component {
  state = {};

  constructor(props) {
    super(props);
    //this.onPriceUpdate(this.props.data?.start, this.props.data?.end, (this.props.data?.maxVisitor ?? 50), this.props.data?.useFree, this.props.data?.id)
  }

  componentDidMount() {
    //this.interAxios.refreshUserData(this.props.userData);
  }

	componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log('componentDidUpdate');
    //console.log('prevProps='+JSON.stringify(prevProps));
    //console.log("this.props=" + JSON.stringify(this.props));
    //console.log("this.state="+JSON.stringify(this.state));
		if (prevProps.data !== this.props.data) {
			this.setState({
				id: this.props.data?.id,
				title: this.props.data?.name === undefined ? "" : this.props.data?.name,
				summary: this.props.data?.description === undefined ? "" : this.props.data?.description,
				start: this.props.data?.start === undefined ? moment().add(1, 'hours').toDate() : moment(this.props.data?.start).toDate()
			})
		}
	}

  onSummaryChange = (summary) => {
    this.setState({summary: summary});
  }

  onTitleChange = (title) => {
    //console.log(title);
    this.setState({title: title});
  }

	onStartTimeChange = (data) => {
    //console.log('!!!'+data);
    //console.log('!!!'+moment(data));
		let arr = data.split(':');
		//let startTime = moment(data);
		let start = moment(this.state.start).hours(arr[0]).minutes(arr[1]).toDate();
		this.setState({start: start});
    //this.onPriceUpdate(start, this.state.end, this.state.maxVisitor, this.state.useFree, this.state.id);
  }

/*	onEndTimeChange = (data) => {
		let arr = data.split(':');
		let end = moment(this.state.end).hours(arr[0]).minutes(arr[1]).toDate();
		this.setState({end: end});
    this.onPriceUpdate(this.state.start, end, this.state.maxVisitor, this.state.useFree, this.state.id);
	}*/


	internalHandleSave = (data) => {
    //console.log(this.state.start);
    let obj = {
      id: this.state.id,
      name: this.state.title,
      description: this.state.summary,
      start: this.state.start == undefined ? moment().add(1, 'hours').valueOf() : moment(this.state.start).valueOf()
    };
    //console.log(JSON.stringify(obj));
    this.props.handleSave(obj);
  }

  render() {
    //console.log('live event modal: ' + this.props.show);
		const client = this.props.client;
    return (
			<GenericModal
				{...this.props}
				internalHandleSave={this.internalHandleSave}
			>
            {
                (this.props.config?.error !== undefined)
            ?
                <>
                  <div style={{color: 'red'}}>{this.props.config.error.message}</div>
                  <div style={{color: 'red'}}>{this.props.config.error.detail}</div>
                </>
            :
                <></>
            }
			<span>Megnevezés</span><br/>
						{
							(this.props.config?.titleEditable ?? false)
						?
							<MDBInput id="jitsi1" onChange={(e) => this.onTitleChange(e.target.value)} value={this.state.title}/>
						:
							<div className="py-3 font-weight-bolder">{this.state.title}</div>
						}
            <MDBRow className="pl-3">
            	<MDBCol>
					<p>Kezdete</p>
					<MDBRow>
						<MDBCol className="">
							<div className="datumformat">{moment(this.props.data?.start).locale('hu').format('YYYY.MM.DD.')}</div>
						</MDBCol>
						<MDBCol>
            {
              (this.props.config?.startTimeEditable ?? false)
            ?
              <MDBTimepicker inline doneText="Beállít" inputLabel="Válasszon időpontot" cancelable closeOnCancel id="startTime" format="24h" defaultValue={moment(this.state.start).format('HH:mm')} onChange={this.onStartTimeChange}/>
            :
              <div className="datumformat">{moment(this.props.data?.start).locale('hu').format('LT')}</div>
            }
            			</MDBCol>
					</MDBRow>
				</MDBCol>
      </MDBRow>

						{
							(this.props.config?.summaryEditable ?? false)
						? <>
              <MDBRow center>
  						      <label className="font-weight-bolder" htmlFor="summary">Leírás</label>
              </MDBRow>
							<CKEditor
	                 id="summary"
									 data={this.state.summary === undefined ? "" : this.state.summary}
	                 editor={ ClassicEditor }
	                 onChange={ ( event, editor ) => {
	                     const data = editor.getData();
	                     this.onSummaryChange(data);
	                 } }
	            />
              </>
						: <>
              <MDBRow center>
                <label className="font-weight-bolder">Leírás</label>
              </MDBRow>
              <MDBRow className="pl-3">
                <div dangerouslySetInnerHTML={{__html: this.state.summary}}/>
              </MDBRow>
						</>}
				</GenericModal>
    );
  }
}
export default function LiveEventModalFunc(props) {
  const context = useContext(AppContext);
  return <LiveEventModal context={context} {...props} />
}
