import React, { Component  } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import  ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor } from 'primereact/editor';

const toolbarOptions = [
  [{ 'font': [] }, { 'size': [] }],
  [ 'bold', 'italic', 'underline', 'strike' ],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'script': 'super' }, { 'script': 'sub' }],
  [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
  [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
  [ 'direction', { 'align': [] }],
  [ 'link', 'image', 'video', 'formula' ],
  [ 'clean' ]                                       
];


import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSelect,
  MDBInput,
  MDBTextArea ,
  MDBRow,
  MDBCol,
  MDBAlert,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBSpinner
} from 'mdb-react-ui-kit';

class BasisModal extends Component {

	constructor(props) {
    super(props);
    this.state = {};
  }

	render() {
    return (
      <div className="modaltemplate-scss">
        <MDBModal tabIndex='-1' show={this.props.toggleBasisModal} staticBackdrop id="faqmodal">
          <MDBModalDialog centered scrollable>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>{this.props.modalTyp}</MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody>
              <MDBRow className="pb-4 justify-content-center d-flex align-items-center">
              <div style={{maxWidth: "653px"}}>
                <span>Sorszám <span style={{color: "red"}}>*</span></span>
                {this.props.serialNumberList !== undefined ?
                <MDBSelect
                  id="dropdown"
                  onValueChange={(e) => this.props.onWeightChange(e)}
                  data={this.props?.serialNumberList}
                />:false}
              </div>
              </MDBRow>
             
             
                <MDBRow className="pb-3 justify-content-center d-flex align-items-center">
                  <div style={{maxWidth: "653px"}}>
                  <span>Kérdés <span style={{color: "red"}}>*</span></span>
                  <CKEditor
                    editor={ ClassicEditor }
                    data={this.props.question}
                    config={{
                      toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'],
                    }}

                    onChange={
                      ( event, editor ) => {
                        const data = editor.getData();
                        this.props.onQuestionChange(data)
                      }
                    }
                  />
                 </div> 
                </MDBRow>
                <MDBRow className="pb-3 justify-content-center d-flex align-items-center">
                  
                  <div style={{maxWidth: "653px"}}>
                  <span>Válasz <span style={{color: "red"}}>*</span></span>
                  <CKEditor
                    editor={ ClassicEditor }
                    data={this.props.answer}
                    config={{
                      toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'],
                    }}

                    onChange={
                      ( event, editor ) => {
                        const data = editor.getData();
                        this.props.onAnswerChange(data)
                      }
                    }
                  />
                  </div>
                </MDBRow>
              
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn type="btn" className="rergistration-button black" onClick={this.props.hideModal}>Mégsem</MDBBtn>
                <MDBBtn type="btn" className="rergistration-button green" onClick={this.props.internalHandleSave}>Mentés</MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
  );
  }
}
export default BasisModal;
