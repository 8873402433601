import React, { Component  } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

class ModalTemplate extends Component {

	constructor(props) {
    super(props);
    this.state = {modalId: "selectsection"};
  }


	render() {
    let btnList = [];
    btnList = this.props.btnForm.map(
      (k, n) => {
            return (
                <MDBBtn key={n} type="btn" className={k.classname} onClick={k.onclick}>{k.text}</MDBBtn>
            );
          }
    );

    return (
    <div className="modaltemplate-scss">
      <MDBModal tabIndex='-1' show={this.props.showModal} id={this.props.modalId} staticBackdrop >
        <MDBModalDialog centered  scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{this.props.modalTyp}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              {this.props.children}
            </MDBModalBody>
            <MDBModalFooter>
              {btnList}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
  }
}
export default ModalTemplate;
