// REACT IMPORTS
import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import moment from "moment";


// CUSTOM COMPONENT IMPORTS
import AppContext from '../../../AppContext';

import emlekezteto from '../../../assets/media/icons/Yoga Logo.svg'
import CountDown from "../../common/timer/CountDown";
// MD BOOTSTRAP IMPORTS
import {
  MDBContainer,
  MDBCol,
  MDBRow
} from 'mdb-react-ui-kit';


/**
 * Props:
 * @param loggedIn: boolean
 * @param logoutClicked: () => {}
 * @returns
 */


const Header = ({loggedIn, logoutClicked }) => {
  const context = useContext(AppContext);
  const [alert, setAlert] = useState({alert: false, alertText: ''});
  const [currentTime, setCurrentTime] = useState(moment());
  const [targetTime, setTargetTime] = useState(moment(context.getState()?.liveTime));
  //const [targetTime, setTargetTime] = useState(moment("2023-09-18 16:00"));
  const timeBetween = moment.duration(moment(context.getState()?.liveTime).diff(currentTime));

  

  // REFS
  const locationInputRef = useRef();
  const searchInputRef = useRef();

  // react router navigation
  const navigate = useNavigate();
  // query params
  const [searchParams] = useSearchParams();


  useEffect(() => {
    if(context.getState()?.loginModalOpened){
      setTimeout(() => document.body.style.overflowY = 'hidden', 100);
    }
    
  }, [context.getState()?.loginModalOpened]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);


  return (
    <header className="alakreform-header">
      {context.isLoggedIn() && context.getState().liveTime !== undefined && (
      <div  className="navbar-top">
       {/* <MDBContainer fluid className="d-flex justify-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center live-training-text">
            <span>Technikai probléma!<br/>Átmenetileg csak korlátozott számú videó érhető el az oldalon. A megértéseteket köszönjük!</span>
        </div>
        </MDBContainer> */}
        <MDBContainer fluid className="d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center live-training-text">
            <img src={emlekezteto}/>
            {context.getState()?.liveTime > moment().valueOf() ? <>
              <span className="counter">
              Élő emlékeztető:  
              {timeBetween.days() > 0 && (
                <> {timeBetween.days()} nap </>
              )
              }
              {timeBetween.hours() > 0 && (
                <> {timeBetween.hours()} óra </>
              )}
              
              <> {timeBetween.minutes()} perc </>

              {timeBetween.days() === 0 && timeBetween.hours() === 0 && timeBetween.minutes() < 10 && (
                <> {timeBetween.seconds()} masodperc </>
              )} múlva indul a következő {context.getState()?.newLiveName}!
            </span>
            </> :
            <span style={{marginLeft: "10px"}}>Élő emlékeztető: Jelenleg folyamatban van egy {context.getState()?.newLiveName}! </span>}
          </div>
        </MDBContainer>
      </div>
              )}
      <div  className="navbar-middle">
        <MDBContainer fluid className="d-flex justify-content-space-between align-items-center">
          
          {!context.isLoggedIn() ?
          <MDBRow className="m-0" style={{width: "100%", position: "relative"}}>
            <div className="d-flex justify-content-center align-items-center title-text">
              <span>RUBINT RÉKA - ONLINE VIDEÓTÁR</span>
            </div>
            <MDBCol className="d-flex align-items-center button-col">
              <div className="d-flex justify-content-center align-items-center belepes-button">
                <button className="rergistration-button white" onClick={() => context.openLoginModal("signin")}>
                  <span>BELÉPÉS</span>
                </button>
              </div>
      
                <div className="d-flex justify-content-end align-items-center regisztracio-button">
                <button className="rergistration-button black" onClick={() => {context.openLoginModal("signup"); setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 200);}}>
                  <span>REGISZTRÁCIÓ</span>
                </button>
              </div>  
            </MDBCol>
          </MDBRow> : 
           <div className="d-flex justify-content-center align-items-center title-text">
              <span>RUBINT RÉKA - ONLINE VIDEÓTÁR</span>
            </div>
          }
          
        </MDBContainer>
      </div>
    </header>
  );
};

export default Header;
