// react imports
import React, { useContext, useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import prev from '../../assets/media/icons/prev.png'
import video_clock from '../../assets/media/icons/video-clock.png'
import video_play from '../../assets/media/icons/video-play.png'
import bookmark_filled from '../../assets/media/icons/bookmark-filled.png'
import bookmark_empty from '../../assets/media/icons/bookmark-empty.png'
import video from '../../assets/media/videos/video_1.mp4'
import ReactPlayer from 'react-player'
import Swiper from 'swiper';
import { FreeMode } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import AppContext from '../../AppContext';
import { MDBContainer
          } from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const VideosDetailPage = (props) => {

  const location = useLocation();
  const context = useContext(AppContext);

  // react router navigation
  const navigate = useNavigate();

  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);
  const [videoHeight, setVideoHeight] = useState(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
  const [isVideoHover, setIsVideoHover] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [starttime, setStarttime] = useState(false);
  const [bookmarkList, setBookmarkList] = useState([])

  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
    setVideoHeight(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
 
  };

  
  

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    setTimeout(() => {setStarttime(true) }, 300)
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);


  useEffect(() => {
    
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setIsVideoHover(true)}, 300)
  }, []);


  useEffect(() => {
    context.interAxios.get('/video', function(response) {
        setVideoList(response.data.content)
        console.log(response.data.content)
    });
    
  
}, []);

  const onPutVideoToBookmark = (id) => {
    context.interAxios.put('/bookmark/' + id, function(response) {
        context.interAxios.get('/bookmark', function(response) {
            setBookmarkList(response.data)
        });
    });
}

const onDeleteVideoFromBookmark = (id) => {
    context.interAxios.delete('/bookmark/' + id, function(response) {
        context.interAxios.get('/bookmark', function(response) {
            setBookmarkList(response.data)
        });
    });
}

const truncateString = (str, num) => {
  if (str.length <= num) {
      return str
    }
        return str.slice(0, num) + '...'
}

const swiper = new Swiper('.swiper-more-video', {
  // Optional parameters
  modules: [FreeMode],
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 3,
      spaceBetween: 0
    },
    // when window width is >= 480px
    600: {
      slidesPerView: 4,
      spaceBetween: 0
    },
    // when window width is >= 640px
    760: {
      slidesPerView: 5,
      spaceBetween: 0
    }
  },
  direction: 'horizontal',
  loop: false,
  effect: 'slide',
  freeMode: {
    enabled: true,
    sticky: true,
  },
});
  return (
    <MDBContainer fluid className="videodetail-container">
      <button className={context.isLoggedIn() ? "black-button" : "black-button logout"} type="button" onClick={() => {
                                
                                  if(location.state.categoryName !== undefined){
                                    navigate('/fooldal', { state: { categoryName: location.state.categoryName  } })
                                  }else {
                                    navigate('/fooldal')
                                  }
                           }}><img src={prev} alt="back"/><span>Back</span></button>
      
      <section className={context.isLoggedIn() ? "first-section" : "first-section logout"} id="">
        <div className={isVideoHover ? "reactplayer-div hover-effect" : "reactplayer-div"}>
           <iframe src={location.state.video.promoUrl !== null ? location.state.video.promoUrl + "&controls=0&autoplay=1&loop=1" : location.state.video.fullUrl + "&controls=0&autoplay=1#t=" + parseInt(location.state.video.duration/180) + "m14s&end=" + parseInt(location.state.video.duration/3 + 120) }  width={videoHeight < videoWidht*0.5625 ? videoHeight/0.5625 :  videoWidht} height={videoHeight < videoWidht*0.5625 ? videoHeight : videoWidht*0.5625 +"px"} allowFullScreen="allowFullScreen" allow="autoplay"
              mozallowfullscreen="mozallowfullscreen" 
              msallowfullscreen="msallowfullscreen" 
              oallowfullscreen="oallowfullscreen" 
              webkitallowfullscreen="webkitallowfullscreen"></iframe>
        </div>
       
      </section>
      <section className="detail-section">
        <div className="video-title">
          <span>
            {location.state.video.name}
          </span>
        </div>
        <div className="timer">
            <img src={video_clock} alt="timer"/>
            <span>{parseInt(location.state.video.duration/60) + "minutes"}</span>
        </div>
        <div className="play-button">
        <button type="button" onClick={() => { 
                if(context.isLoggedIn()){
                  navigate('/videoplay', { state: { fullUrl: location.state.video.fullUrl, categoryName: undefined} })
                }else {
                    context.openLoginModal("elofizetekPopup");
                }
          }}><img src={video_play} alt="player"/></button>
        </div>
        <div className="video-description">
          <span>{location.state.video.description}</span>
        </div>
        {context.isLoggedIn() && (
          <div className="bookmark-div pb-5">
            <button className="bookmark-button" type="button" onClick={location.state.video.isBookmarkList ? 
                              () => onDeleteVideoFromBookmark(location.state.video.id) : () => onPutVideoToBookmark(location.state.video.id)}>
              <img src={location.state.video.isBookmarkList ? bookmark_filled : bookmark_empty} alt="bookmark" />
            </button>
            <span>Hozzáadás a kedvencekhez</span>
          </div>
        )}
       
      </section>
     
    </MDBContainer>


  );
};

export default VideosDetailPage;
