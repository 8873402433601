// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha"
import Cookies from 'universal-cookie';

const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBIcon,
  MDBInputGroup,
  MDBCheckbox,
  MDBLoadingManagement
} from 'mdb-react-ui-kit';

import aszf from '../../static/jogi/aszf.pdf'
import privacy from '../../static/jogi/privacy.pdf'



// STYLE IMPORTS
import "react-toggle/style.css";


import splogo from '../../assets/media/logo/splogo.png'
import paypal from '../../assets/media/logo/paypal.png'


class ProfileForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "",
                    firstName: "",
                    lastName: "",
                    password: "",
                    confirmPassword: "",
                    fullName: "",
                    address: "",
                    postalCode: "",
                    city: "",
                    kuponkod: "",
                    isPayment: false       
      };
  };
/*
  componentDidMount(){
    document.getElementById("controlledCheckbox_1").setCustomValidity("Kérjük fogadd el a nyilatkozatot, kattints a körbe!");
    document.getElementById("controlledCheckbox_2").setCustomValidity("Kérjük fogadd el a nyilatkozatot, kattints a körbe!");
  }
*/
    onCustomer = (e) => {
      e.preventDefault();
      let self = this;
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const token = this.props.captchaRef.current.getValue();
      if(token !==""){
        if(this.state.password === this.state.confirmPassword){
          if(this.state.email.match(validRegex)){
            this.setState({isPayment: true})
            if(this.state.kuponkod === ""){
              if(this.props.isSimplepay){
                this.props.context.interAxios.post('/auth/register',
                {
                  email: this.state.email,
                  password: this.state.password,
                  lastName: this.state.lastName,
                  firstName: this.state.firstName,
                  captchaToken: token,
                  directMarketingConsent: this.props.checkedMarketing,
                  coupon: "",
                  billing: {
                    name: this.state.fullName,
                    postcode: this.state.postalCode,
                    country: "HU",
                    region: "",
                    location: this.state.city,
                    address: this.state.address,
                    vatCode: ""
                  },
                  paymentService: "SIMPLEPAY"
                  }, function(response) {
                    if(response.data.paymentUrl !== ""){
                      self.props.context.onPaymentProcess(response.data.paymentUrl, "SIMPLEPAY");
                      self.props.context.closeLoginModal()
                      self.props.captchaRef.current.reset();
                      self.props.context.openLoginModal("succesRegistration");
                      setTimeout(() => {
                        //self.props.context.closeLoginModal();
                        window.open(response.data.paymentUrl,"_self").focus();
                        
                      }, 2000);
                    }else {
                      alert('A regisztráció sikertelen! Ellenörizd a megadott adatokat!')
                    }
                       
                  }, function (error) {
                    alert('A regisztráció sikertelen! Ellenörizd a megadott adatokat!')
                });
              }
              if(this.props.isPaypal) {
               //alert("A PayPal fejlesztői környezetének karbantartása miatt jelenleg ez a funkció nem elérhető. Kérünk próbálkozz később ismét!");
                this.props.context.interAxios.post('/auth/register',
                {
                  email: this.state.email,
                  password: this.state.password,
                  lastName: this.state.lastName,
                  firstName: this.state.firstName,
                  captchaToken: token,
                  directMarketingConsent: this.props.checkedMarketing,
                  coupon: "",
                  billing: {
                    name: this.state.fullName,
                    postcode: this.state.postalCode,
                    country: "HU",
                    region: "",
                    location: this.state.city,
                    address: this.state.address,
                    vatCode: ""
                  },
                  paymentService: "PAYPAL"
                  }, function(response) {
                    if(response.data.paymentUrl !== ""){
                      self.props.context.onPaymentProcess(response.data.paymentUrl, "PAYPAL");
                      self.props.context.closeLoginModal()
                      self.props.captchaRef.current.reset();
                      self.props.context.openLoginModal("succesRegistration");
                      setTimeout(() => {
                        
                        //self.props.context.closeLoginModal();
                        window.open(response.data.paymentUrl,"_self").focus();
                      }, 2000);
                    }else {
                      alert('A regisztráció sikertelen! Ellenörizd a megadott adatokat!')
                    }
                       
                  }, function (error) {
                    alert('A regisztráció sikertelen! Ellenörizd a megadott adatokat!')
                });
              }
            } else {
              this.props.context.interAxios.post('/auth/register',
              {
                email: this.state.email,
                password: this.state.password,
                lastName: this.state.lastName,
                firstName: this.state.firstName,
                captchaToken: token,
                directMarketingConsent: this.props.checkedMarketing,
                coupon: this.state.kuponkod,
                billing: {
                  name: this.state.fullName,
                  postcode: this.state.postalCode,
                  country: "HU",
                  region: "",
                  location: this.state.city,
                  address: this.state.address,
                  vatCode: ""
                },
                paymentService: "NONE"
                }, function(response) {
                    //self.props.context.onPaymentProcess(response.data.paymentUrl, "SIMPLEPAY");
                    //self.props.context.closeLoginModal()
                    //self.props.captchaRef.current.reset();
                    console.log(response.data)
                    self.props.context.openLoginModal("signin");

                }, function (error) {
                  console.log(error.data)
                  //alert('A regisztráció sikertelen! Ellenörizd a megadott adatokat!')
              }
             
              );
            }
           
            
              //this.props.hideModal();
          }else {
            alert('Az Emailformátum helytelen!')
          }
         
        }else {
          alert('Ellenőrizd a megadott jelszavakat!')
        }
      }else {
        alert('Jelöld be, hogy nem vagy robot!')
      }
      
     
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
  
  render() {
    
    return (<>
              <form onSubmit={(e) =>  {if(!this.state.isPayment){
                                                  this.onCustomer(e)}}} className="profile-form" autoComplete="off">
                <div className="white-panel">
                  {/** FIRST NAME **/}
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6'>
                      <label htmlFor="lastName">Vezetéknév*</label>
                      <input
                        id='lastName'
                        name='lastName'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.lastName}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.firstnameRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md='6'>
                      <label htmlFor="firstName">Keresztnév*</label>
                      <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.firstName}
                        ref={this.props.firstnameRef}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.emailRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                
                  {/** EMAIL **/}
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6'>
                      <label htmlFor="email">E-mail cím*</label>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        ref={this.props.emailRef}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.passwordfirstRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>


                  {/** PASSWORDs **/}
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6'>
                      <label htmlFor="password">Jelszó*</label>
                      <MDBInputGroup
                        className="jc-password"
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePassword ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePassword(!this.props.hidePassword)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePassword ? 'password' : 'text'} 
                          value={this.state.password}
                          onChange={this.changeHandler}
                          ref={this.props.passwordfirstRef}
                          id='password'
                          name='password'
                          onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.passwordtwoRef.current.focus()
                            }
                          }}
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                    <MDBCol md='6'>
                      <label htmlFor="confirmPassword">Jelszó mégegyszer*</label>
                      <MDBInputGroup
                        className="jc-password"
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePasswordtwo ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePasswordtwo(!this.props.hidePasswordtwo)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePasswordtwo ? 'password' : 'text'} 
                          value={this.state.confirmPassword}
                          onChange={this.changeHandler}
                          ref={this.props.passwordtwoRef}
                          id='confirmPassword'
                          name='confirmPassword'
                          onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.nameRef.current.focus()
                            }
                          }}
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="py-2 m-0">
                    <MDBCol md='6' className="p-0">
                      <MDBRow className="pb-2 m-0">
                        <MDBCol md='12' size="12">
                          <span className="modal-title-form">Számlázási adatok</span>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="py-2 m-0">
                        <MDBCol md='12'>
                          <label htmlFor="address">Számlázási név</label>
                          <input
                            id='fullName'
                            name='fullName'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.fullName}
                            ref={this.props.nameRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.addressRef.current.focus()
                              }
                            }}
                            required
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="pb-2 m-0">
                        <MDBCol md='12' size="12">
                          <label htmlFor="address">Utca, házszám*</label>
                          <input
                            id='address'
                            name='address'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.address}
                            ref={this.props.addressRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.postcodeRef.current.focus()
                              }
                            }}
                            required
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="pb-5 m-0">
                        <MDBCol md='4' size="12">
                        <label htmlFor="postalCode">Irányítószám*</label>
                          <input
                            id='postalCode'
                            name='postalCode'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.postalCode}
                            ref={this.props.postcodeRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.cityRef.current.focus()
                              }
                            }}
                            required
                          />
                        </MDBCol>
                        <MDBCol md='8'>
                        <label htmlFor="city">Város*</label>
                          <input
                            id='city'
                            name='city'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.city}
                            ref={this.props.cityRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.kuponRef.current.focus()
                              }
                            }}
                            required
                          /> 
                        </MDBCol>
                      </MDBRow>
                      
                     
                    </MDBCol>
                    <MDBCol md='6' className="p-0">
                      <MDBRow className="pb-2 m-0">
                        <MDBCol md='12'>
                          <span className="modal-title-form">Megrendelés</span>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="py-2 m-0">
                        <MDBCol md='12'>
                          <div className="d-grid align-items-center justify-content-center apply-card-modal green">
                          <span className="title">Legyél a videótáram legaktívabb tagja!</span>
                    {/*<span className="description">- ONLINE VIDEÓTÁR valami marketing szöveg...</span>*/}
                    <div className="price">
                        <MDBRow style={{width: "100%"}} className="ps-4 pt-2">
                        <span className="description">Havidíj,</span>
                        </MDBRow>
                        <MDBRow className=" text-center">
                        <span className="price-text">2990 Ft</span>
                        </MDBRow>
                        
                        
                    </div>
                          </div>
                        </MDBCol>
                    
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="py-2 m-0">
                    <MDBCol md='12' className="p-0">
                      <MDBRow className="pb-2 m-0">
                        <MDBCol md='12'  className="d-grid align-items-center justify-content-start ">
                          <div className="checkbox-div d-flex align-items-start pb-4">
                            <MDBCheckbox
                              id='controlledCheckbox_1'
                              label=""
                              checked={this.props.checked}
                              setCustomValidity="message"

                              onChange={() => this.props.setChecked(!this.props.checked)}
                              required
                            /> 
                            <div>
                            Kijelentem, hogy az Alakreform Kft.  <a href={aszf} target="_blank">általános szerződési feltételeket </a>feltételeket teljes körűen megismertem és 
                            elfogadom, az abban foglalt rendelkezéseket magamra nézve kötelezőnek ismerem el.
                            </div>
                            
                          </div>   
                          <div className="checkbox-div d-flex align-items-start pb-4">
                            <MDBCheckbox
                              id='controlledCheckbox_2'
                              label=""
                              checked={this.props.checked_2}
                              onChange={() => this.props.setChecked_2(!this.props.checked_2)}
                              required
                            /> 
                            <div>
                            Kijelentem, hogy az <a href={privacy} target="_blank">adatkezelési tájékoztatójának </a>tartalmát teljes körűen megismertem és az adatkezelési 
                            tájékoztató tartalmát tudomásul vettem, továbbá hozzájárulok ahhoz, hogy a személyes adataimat az adatkezelési tájékoztatóban meghatározottak szerinti céllal és feltételekkel kezelje az Alakreform Kft. 
                            </div>
                            
                          </div>   
                          <div className="checkbox-div d-flex align-items-start pb-4">
                            <MDBCheckbox
                              id='controlledCheckbox_3'
                              label=""
                              checked={this.props.checkedMarketing}
                              onChange={() => this.props.setCheckedMarketing(!this.props.checkedMarketing)}
                            /> 
                            <div>
                            Önkéntesen hozzájárulok, hogy e-mail címemet az Alakreform Kft. visszavonásig direkt marketing célra, hírlevél küldése érdekében kezelje az 
                            általam megismert adatkezelési tájékoztatónak megfelelően, és részemre ajánlatokat küldjön, aktuális ajánlatairól tájékoztasson. A hozzájárulás a jövőre nézve bármikor visszavonható a   
                            <a href={"mailto:rendezveny@rubintreka.hu"} target="_blank"> rendezveny@rubintreka.hu </a>e-mail cím alatt.
                            </div>
                            
                          </div>     
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                 
                  </MDBRow>

                  <MDBRow center className="py-2 m-0">
                    <MDBCol md='12' className="d-flex justify-content-center align-items-center p-0">
                        <div className="kuponkod-div">
                          <label htmlFor="kuponkod">Kuponkód</label>
                          <input
                            id='kuponkod'
                            name='kuponkod'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.kuponkod}
                            ref={this.props.kuponRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.buttonRef.current.focus()
                              }
                            }}
                          />
                        </div>
                    </MDBCol>
                  </MDBRow>

                  {this.state.kuponkod === "" && (
                    <MDBRow center className="pt-4 m-0">
                    <MDBCol md='6' className="p-0">
                      <div className="checkbox-div d-flex align-items-center justify-content-center pb-4">
                        <MDBCheckbox
                            id='controlledCheckbox_3'
                            label=""
                            checked={this.props.isSimplepay}
                            onChange={() => {this.props.setIsPaypal(false); this.props.setIsSimplepay(!this.props.isSimplepay)}}
                          /> 
                      
                        <a href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="-blank"><img src={splogo} height='40' style={{marginLeft: "15px"}}/></a>
                      </div>
                    </MDBCol>
                    <MDBCol md='6' className="p-0">
                      <div className="checkbox-div d-flex align-items-center justify-content-center pb-4">
                        <MDBCheckbox
                          id='controlledCheckbox_3'
                          label=""
                          checked={this.props.isPaypal}
                          onChange={() => {this.props.setIsSimplepay(false); this.props.setIsPaypal(!this.props.isPaypal)}}
                        /> 
                        {/* <MDBCheckbox
                          id='controlledCheckbox_3'
                          label=""
                          checked={this.props.isPaypal}
                          onChange={() => alert("Karbantartási szünet miatt a PayPal előfizetés átmenetileg nem használható!")}
                        /> */}
                        <img src={paypal} height='30' style={{marginLeft: "15px"}}/>
                      </div>
                    </MDBCol>
                    </MDBRow>
                  )}
                   

                  <MDBRow className="py-2 m-0">
                    <MDBCol md='12' className="p-0 d-flex justify-content-center align-items-center ">
                      <ReCAPTCHA
                        ref={this.props.captchaRef}
                        sitekey={"6LdU-FAoAAAAALMMm9vjM3lQQJiLLQN5duzkGyP8"}
                      />
                    </MDBCol>
                  </MDBRow>
                  {this.state.isPayment && (
                    <div ref={this.props.colorsRef} style={{ height: "70px", width: "100%", zIndex: "1029" }}>
                      <MDBLoadingManagement color='success' parentRef={this.props.colorsRef} backdropColor='blue' backdropOpacity={0.2} />
                    </div>
                  )}
                  {this.state.kuponkod === "" ? 
                  
                  <>
                      {(!this.props.isPaypal && !this.props.isSimplepay) ? 
                        <MDBRow center className="pb-3 m-0">
                          <MDBCol md='12' className="p-0">
                                <div className="d-flex justify-content-center align-items-center pt-3">
                                  <button type="button" onClick={() => alert("Válassz ki egy fizetési módot!")} className="rergistration-button modal-button black-pay ">
                                    <span>Előfizetés</span>
                                  </button>
                                </div>
                          </MDBCol>
                          
                        </MDBRow>:
                        <MDBRow center className="pb-3 m-0">
                          <MDBCol md='12' className="p-0">
                                <div className="d-flex justify-content-center align-items-center pt-3">
                                  <button type="submit" className={!this.state.isPayment ? "rergistration-button modal-button green" : "rergistration-button modal-button black"} ref={this.props.buttonRef}>
                                    <span>Előfizetés</span>
                                  </button>
                                </div>
                          </MDBCol>
                        </MDBRow>}
                  </>:
                      <MDBRow center className="pb-3 m-0">
                      <MDBCol md='12' className="p-0">
                            <div className="d-flex justify-content-center align-items-center pt-3">
                              <button type="submit" className={!this.state.isPayment ? "rergistration-button modal-button green" : "rergistration-button modal-button black"} ref={this.props.buttonRef}>
                                <span>Előfizetés</span>
                              </button>
                            </div>
                      </MDBCol>
                    </MDBRow>
                  }
                 
                 
                {/*  <div className="checkbox-div d-flex align-items-center pb-4 pt-3">
                          <span>Figyelem! A regisztrációtól számított első hónap ingyenes! A második hónaptól a tagsági díj: 2990 Ft!
                                Ne ijedj meg, ha most a bankkártya adataid megadása után ez az összeg (2990 Ft) levonásra (zárolásra) kerül, mert azonnal vissza is utalja a rendszer! Erre a lépésre a bankkártya hitelesítése miatt van szükség! Pénzlevonásra az első hónapban nem kerül sor!</span>
                  </div>*/}
                  </div>
              </form>

               
      </>
    );
  }
};

function ProfileFormFunc(props) {
  let [searchParams] = useSearchParams();
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordtwo, setHidePasswordtwo] = useState(true);
  const [checked, setChecked] = useState(false);
  const [isPaypal, setIsPaypal] = useState(false);
  const [isSimplepay, setIsSimplepay] = useState(false);
  const [checked_2, setChecked_2] = useState(false);
  const [checkedMarketing, setCheckedMarketing] = useState(false);
  const navigate = useNavigate();
  const firstnameRef = useRef();
  const emailRef = useRef();
  const passwordfirstRef = useRef();
  const passwordtwoRef = useRef();
  const nameRef = useRef();
  const addressRef = useRef();
  const postcodeRef = useRef();
  const cityRef = useRef();
  const kuponRef = useRef();
  const buttonRef = useRef();
  const buttonRef_2 = useRef();
  const captchaRef = useRef()
  const colorsRef = useRef(null);
  return <ProfileForm searchParams={searchParams} 
                      navigate={navigate} 
                      hideModal={props.hideModal} 
                      context={props.context} 
                      firstnameRef={firstnameRef}
                      emailRef={emailRef}
                      passwordfirstRef={passwordfirstRef}
                      passwordtwoRef={passwordtwoRef}
                      nameRef={nameRef}
                      addressRef={addressRef}
                      postcodeRef={postcodeRef}
                      cityRef={cityRef}
                      kuponRef={kuponRef}
                      buttonRef={buttonRef}
                      buttonRef_2={buttonRef_2}
                      hidePassword={hidePassword}
                      setHidePassword={setHidePassword}
                      hidePasswordtwo={hidePasswordtwo}
                      setHidePasswordtwo={setHidePasswordtwo}
                      checked={checked}
                      checked_2={checked_2}
                      checkedMarketing={checkedMarketing}
                      setChecked={setChecked}
                      setChecked_2={setChecked_2}
                      setCheckedMarketing={setCheckedMarketing}
                      captchaRef={captchaRef}
                      isPaypal={isPaypal}
                      setIsPaypal={setIsPaypal}
                      isSimplepay={isSimplepay}
                      setIsSimplepay={setIsSimplepay}
                      colorsRef={colorsRef}
                      />;
}

export default ProfileFormFunc;
