import React, { useContext, useState } from "react";
import AppContext from '../../AppContext';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCheckbox
} from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const FeliratkozasHirlevelModal = (props) => {

  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);

  const context = useContext(AppContext);

  const onGetAllNewsletter = () => {
    let self = this;
    if(email !== "") {
      if(checked){
        context.interAxios.post('/newsletter', {email: email}, function(response) {
          setEmail("");
          props.hideModal()
          context.openLoginModal("hirlevel");
        });
      }else {
        alert("Fogadja el a hozájárulást!")
      }
      
    }else {
      alert("Add meg az emailcímedet!")
      
    }
  }

    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={props.visible}  className="feliratkozas-modal">
      <MDBModalDialog scrollable centered size='xl'>
        <MDBModalContent>
        <MDBRow>
          <MDBCol lg="6" className="hirelvel-col" id="hirlevel-col" style={{height: document.getElementById("hirlevel-col")?.clientWidth*1185/1674}}>

          </MDBCol>
          <MDBCol lg="6">
            <MDBModalBody>
          
              <MDBModalTitle className="pt-3">
                <span className="modal-title">Iratkozz fel hírlevelünkre! Ne mulassz el semmit!</span>
              </MDBModalTitle>
              
                <div className="email-form">
                <MDBInput value={email}
                          name='email'
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          label='Email'/>
                <div className="checkbox-div d-flex align-items-start pt-4">
                  <MDBCheckbox
                    id='controlledCheckbox'
                    label=""
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  /> 
                  <div className="hozzajarulas">
                    <span>
                        Önkéntesen hozzájárulok, hogy e-mail címemet az Alakreform Kft. visszavonásig direkt marketing célra, hírlevél küldése érdekében kezelje az 
                        általam megismert adatkezelési tájékoztatónak megfelelően, és részemre ajánlatokat küldjön, aktuális ajánlatairól tájékoztasson. A hozzájárulás a jövőre nézve bármikor visszavonható a   
                        <a href={"mailto:rendezveny@rubintreka.hu"} target="_blank"> rendezveny@rubintreka.hu </a>e-mail cím alatt. 
                    </span>
                  </div>
                  
                </div>  
                <div className="justify-content-center d-flex align-ites-center pt-1" >
                  <button type="button" className="rergistration-button modal-button green" onClick={() => {cookies.set('hirlevel', {isHirlevel: true}, { path: '/'}); onGetAllNewsletter()}}>
                  <span>Feliratkozás</span>
                  </button>
                </div>
              </div>
              <div className="justify-content-center d-flex align-ites-center3" >
                <button type="button" className="rergistration-button text-button" onClick={() => {cookies.set('hirlevel', {isHirlevel: true}, { path: '/'});
                                                                                                          props.hideModal()}}>
                <span>Zárd be</span>
                </button>
              </div>
          
            </MDBModalBody>
          </MDBCol>
        </MDBRow>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default FeliratkozasHirlevelModal;