import React, { Component } from "react";
import{
	MDBModal,
	MDBModalHeader,
	MDBModalDialog,
	MDBModalContent,
	MDBModalBody,
	MDBContainer,
	MDBModalFooter,
	MDBBtn,
	MDBRow,
	MDBCol
} from 'mdb-react-ui-kit';
import peoplewhite from "../../../../assets/media/newicons/peoplewhite.png";

class GenericModal extends Component {
  state = {}


  render() {
		const client = this.props.client;
		//console.log('GenericModal: ' + this.props.show);
    return (
      <MDBContainer>
        <MDBModal className="signin-modal" staticBackdrop tabIndex='-1' closeOnEsc={false} show={this.props.show} >
			<MDBModalDialog centered>
				<MDBModalContent>
          <MDBModalHeader>
							<MDBRow center style={{width: "100%"}}>
							<MDBCol md="6" className="d-flex justify-content-start align-items-center">
					    	<span className="jitsi-modal-title">{this.props.config?.modalTitle ??'Modal'}</span>
							</MDBCol>
							<MDBCol md="6" className="d-flex justify-content-end align-items-center p-0">
					    	<MDBBtn className='btn-close' color='none' onClick={this.props.handleClose}></MDBBtn>
							</MDBCol>
							</MDBRow>
          </MDBModalHeader>
          <MDBModalBody>
            {this.props.children}

            {/*<MDBBtn color="secondary" onClick={this.props.handleClose}>Schließen</MDBBtn>*/}
            {(this.props.config?.okButton?.show ?? true) ?
							<MDBRow center className="pt-3">
							<MDBBtn	type="button"
											className="rergistration-button modal-button green"
											onClick={this.props.internalHandleSave}><span><img src={peoplewhite} className="pr-2"/> {this.props.config?.okButton?.text ?? 'Mentés'}</span>
							</MDBBtn>
							</MDBRow>
						: false }

						{(this.props.config?.thirdButton?.show ?? false) ?
							<MDBRow center>
							<MDBBtn	type="button"
											className="rergistration-button modal-button green"
											onClick={(event) => this.props.config?.thirdButton?.handleClick(this.props.data)}><span><img src={peoplewhite}  className="pr-2"/> {this.props.config?.thirdButton?.text}</span>
							</MDBBtn>

							</MDBRow>
						: false }

          </MDBModalBody>
		  </MDBModalContent>
		  </MDBModalDialog>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default GenericModal;
