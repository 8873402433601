import React, { Component, useRef, useContext   } from "react";
import AppContext from '../../AppContext';
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalHeader,
  MDBBtn,
  MDBLoadingManagement
} from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class GeneralInfoModal extends Component {

	constructor(props) {
    super(props);
    this.state = {
    };
  }
  


	render() {
    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={this.props.visible}  className="signup-modal">
      <MDBModalDialog centered scrollable>
        <MDBModalContent>
        <MDBModalHeader>
            <MDBBtn className='btn-close' color='none' onClick={this.props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title pt-4">Továbbítás a fizetési oldalra</span></MDBModalTitle>
              {
                  this.props.context.getState()?.paymentService === "PAYPAL" ? 
                  <span className="modal-text">Rövidesen átirányítunk a PayPal fizetési oldalára.
                  Amennyiben az oldal pár másodperc után sem nyílik meg, kattints az alábbi <a href={this.props.context.getState()?.paymentUrl}  target="_blank" rel="noopener noreferrer">linkre</a>.</span>:
                  <span className="modal-text">Rövidesen átirányítunk a SimplePay fizetési oldalára.
                   Amennyiben az oldal pár másodperc után sem nyílik meg, kattints az alábbi <a href={this.props.context.getState()?.paymentUrl}  target="_blank" rel="noopener noreferrer">linkre</a>.</span>
                
              }
              
              <div ref={this.props.basicRef}  style={{ height: "100px", width: "100%", zIndex: "1029"}}>
                <MDBLoadingManagement  loadingText="Betöltés" parentRef={this.props.basicRef} />
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
  }
}
export default function GeneralInfoModalFunc(props) {
  const basicRef = useRef(null);
  const context = useContext(AppContext);
  return <GeneralInfoModal basicRef={basicRef}
                   visible= {props.visible}
                   hideModal= {props.hideModal}
                   context= {context}/>;
}