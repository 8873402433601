// react imports
import React, { useContext, useState, useRef, useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// CUSTOM COMPONENTS
import AppContext from '../../AppContext';

const CarouselComponentProgramok = (props) => {

  //MDBAlert

  // REACT ROUTER PARAMS - for processing url
  const context = useContext(AppContext);

  // STATES
  

   // react router navigation
   const navigate = useNavigate();


  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 920 },
      items: 2,
      partialVisibilityGutter: 3
    },
    
    small: {
        breakpoint: { max: 920, min: 0 },
        items: 2,
        partialVisibilityGutter: 1
    }
  };



 
  return (
    <div className="program-carousel" >
      <Carousel
              additionalTransfrom={0} 
              swipeable={true}
              draggable={!window.matchMedia('(max-device-width: 740px)').matches ? false :  true}
              showDots={!window.matchMedia('(max-device-width: 740px)').matches ? true :  false}
              responsive={responsive}
              infinite={true}
              renderArrowsWhenDisabled={true}
              renderDotsOutside={true}
              slidesToSlide={2}
              centerMode={false}
              keyBoardControl={true}
              customTransition={window.matchMedia('(max-device-width: 400px)').matches ? "transform 100ms ease-in-out" : "transform 1000ms ease-in-out"}
              transitionDuration={window.matchMedia('(max-device-width: 400px)').matches ? 100 : 1000}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["small"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
      >
        {props.gallery.map((k,n) => {
            return(
              <div className=" image-items px-2 pb-3" key={n} onClick={() => props.openLightboxlick()}>
                <img className="gallery-image" src={k.url} alt={n+1}/>
              </div>
              
        )})}
      </Carousel>
    </div>


  );
};

export default CarouselComponentProgramok;
