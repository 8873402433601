import React, { Component, useContext, useRef  } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


import {
  MDBContainer,
  MDBCard,
} from 'mdb-react-ui-kit';


class ImageUploadPage extends Component {
  
  constructor(props) {
  	super(props);
    this.state = {src: null,
      crop: {
        unit: 'px',
        x: 0,
        y: 0,
        width: 1490,
        height: 900,
      }, fotolist: [], selectedFiles: []};
	}

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width; //1080 / 402
    const scaleY = image.naturalHeight / image.height; //396 / 147
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {

      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({url: this.fileUrl, blob: blob});
      }, 'image/jpeg');
    });
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
       this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({fotoName: e.target.files[0].name});
      
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = event => {
    if(event.target.naturalWidth >= 1280){
      this.setState({imageRef: event.target});
    }else{
      
      alert("Image size not correct. Please add at least 1280px wide image.")
    }

  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.state.imageRef && crop.width && crop.height) {

      const croppedImage = await this.getCroppedImg(
        this.state.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.onCropCompleteFinal(croppedImage);
      this.setState({ croppedImageUrl: croppedImage.url});
    }
  }

  onCropCompleteFinal = (image) => {
    this.setState({url: image.url, blob: image.blob});
    let selectedFiles = this.state.selectedFiles;
    selectedFiles.push({fotoUrl: image.url, value: image.blob})
    this.setState({selectedFiles: selectedFiles, image: image.url, blob: image.blob});
  };

  anAddFotoToList = () => {
    let fotolist = this.state.fotolist;
    fotolist.push({fotoName: this.state.fotoName})
    this.setState({
      fotolist: fotolist, croppedImageUrl: "", fotoName: "", src: ""
    })
  }

  handleSaveImage = () => {
    if(this.state.selectedFiles.length > 0 ){
      let self = this;
      for(let i=0; i<this.state.selectedFiles.length; i++){
        const formData = new FormData
        formData.append("file", this.state.selectedFiles[i].value);
        this.props.context.interAxios.post('/admin/coverimage', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
          
        });
      }
      this.setState({
        fotolist: [], selectedFiles: [], croppedImageUrl: "", fotoName: "", src: ""
      })
      
    }
  }




  render(){
    let fotoList = this.state.fotolist.map((k,n) => {
      
      return (
      <div key={n}>{k.fotoName}</div>
    )})

    return (
      <div style={{paddingLeft: "290px"}}>
        <MDBContainer className='pt-4 pt-lg-5'>
          <MDBCard style={{ width: '100%', padding: "15px"}}>
            <div className="">
              <div className="pb-3">
                <input type="file" accept="image/*" onChange={this.onSelectFile} />
              </div>
              {this.state.src && (
                <ReactCrop
                  ruleOfThirds
                  onChange={this.onCropChange}
                  crop={this.state.crop}
                  onComplete={this.onCropComplete}
                >
                  <img alt="Crop"
                                  src={this.state.src}
                                  onLoad={this.onImageLoaded}
                                  />
                </ReactCrop>
              )}
              {this.state.croppedImageUrl && (
                <div className="d-flex align-items-center justify-content-center">
                <img alt="Crop" style={{ maxWidth: '60%' }} src={this.state.croppedImageUrl} />
                </div>
              )}
            </div>
            <div className="d-flex align-items-center">
            <button type="button" className="rergistration-button modal-button green" onClick={() => this.anAddFotoToList()}>
              <span>Mentés a listához</span>
            </button>
            </div>

            <div>
              Feltöltött képek listája:
                <div>
                  {fotoList}
                </div>
            </div>
            <button type="button" className="rergistration-button modal-button green" onClick={() => this.handleSaveImage()}>
              <span>Fotók feltöltése</span>
            </button>
          </MDBCard>
          
          <div className="p-5" style={{height: "50px"}}/>
        </MDBContainer>
      </div>
    );
  }

}

export default function ImageUploadPageFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const nodeRef = useRef(null)
    return <ImageUploadPage context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef} />;
}
