// REACT IMPORTS
import React, { useContext, Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppContext from '../../AppContext';
import moment from "moment";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBIcon,
  MDBInputGroup,
  MDBLoadingManagement
} from 'mdb-react-ui-kit';

// STYLE IMPORTS
import "react-toggle/style.css";


class SignInForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "",
                    password: "" ,   
      };
    };

    onCustomer = (e) => {
      e.preventDefault();
      let customer = {
                    email: this.state.email,
                    password: this.state.password    
      };
      this.props.loginEvent(this.state.email, this.state.password)
     
      //this.props.context.onSignIn()
      //this.props.hideModal();
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    onEnterLive = () => {
      let self = this;
      this.props.context.setIsLoading(true)
      this.props.context.interAxios.post('/auth/live', {password: this.state.password, email: this.state.email}, function(response) {
        //context.onSignIn(response.data);
        self.props.context.onSetNewLiveVideo(response.data)
        self.props.navigate('/livevideoplay')
        self.props.context.setIsLoading(false);
        self.props.context.closeLoginModal();
        
      }, function(error) {
        alert("Belépés sikertelen! Ellenörizze megadott adatait vagy előfizetése érvényességét és próbálkozzon újra! Köszönjük türelmét!");
      });
    }

  
  render() {
    return (<>
     
           
     
       
              <form onSubmit={(e) => {if(!this.props.context.getState()?.isLoading){
                                                  this.onCustomer(e)}}} className="signin-form" autoComplete="off">
                <div className="white-panel">

                {this.props.context.getState().liveTime !== undefined && this.props.timeBetween < 30*60*1000 && (
                  <div className="d-flex justify-content-center align-items-center pb-4 ">
                  {this.props.timeBetween > 0 ? <>
                        <span className="counter">
                          <> {parseInt(this.props.timeBetween/(60*1000))} perc múlva indul a következő LIVE edzésünk!</>
                        </span>
                      </> :
                      <span style={{marginLeft: "10px"}}>Jelenleg folyamatban van egy LIVE edzés! </span>}
                  </div>
                )}
                  {/** EMAIL **/}
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="firstName">Felhasználó név / E-mail cím</label><br/>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.passwordRef.current.focus()
                          }
                        }}
                        value={this.state.email}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  {/** PASSWORDs **/}
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="password">Jelszó</label><br/>
                      <MDBInputGroup
                        className="jc-password"
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePassword ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePassword(!this.props.hidePassword)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePassword ? 'password' : 'text'} 
                          value={this.state.password}
                          onChange={this.changeHandler}
                          ref={this.props.passwordRef}
                          id='password'
                          name='password'
                          onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.buttonRef.current.focus()
                            }
                          }}
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                  </MDBRow>
                  {this.props.context.getState()?.isLoading && (
                    <div ref={this.props.colorsRef} style={{ height: "70px", width: "100%", zIndex: "1029" }}>
                      <MDBLoadingManagement color='success' parentRef={this.props.colorsRef} backdropColor='blue' backdropOpacity={0.2} />
                    </div>
                  )}
                 

                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-center" >
                    {this.props.context.getState().liveTime !== undefined && this.props.timeBetween < 30*60*1000 ?
                    <>
                    <div>
                      <button type="button" className="rergistration-button modal-button live" onClick={() => this.onEnterLive()}>
                      <span>Live megtekintése</span>
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="rergistration-button modal-button green" ref={this.props.buttonRef}>
                      <span>Belépek</span>
                      </button>
                    </div>
                    </>:
                    <div>
                      <button type="submit" className="rergistration-button modal-button green" ref={this.props.buttonRef}>
                      <span>Belépek</span>
                      </button>
                    </div>
                    }
                   
                          <div>
                            <button type="button" className="rergistration-button modal-button  black" 
                            onClick={() => {this.props.context.closeLoginModal(); this.props.context.openLoginModal("signup");}}>
                            <span>Előfizetés</span>
                            </button>
                          </div>
                    
                          <div>
                            <button type="button" className="rergistration-button modal-button text-button" 
                            onClick={() => {this.props.context.closeLoginModal(); this.props.context.openLoginModal("forgotpassword");}}>
                            <span>Elfelejtetted a jelszavad?</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>

                </div>
              </form>
      </>
    );
  }
};

function SignInFormFunc(props) {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const passwordRef = useRef();
  const buttonRef = useRef();
  const colorsRef = useRef(null);
  const [hidePassword, setHidePassword] = useState(true);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [targetTime, setTargetTime] = useState(context.getState()?.liveTime);
  //const [targetTime, setTargetTime] = useState(moment("2023-09-18 16:00"));
  const timeBetween = context.getState()?.liveTime-Date.now();

  return <SignInForm searchParams={searchParams} 
                     navigate={navigate} 
                     hideModal={props.hideModal} 
                     context={context} 
                     loginEvent={props.loginEvent} 
                     passwordRef={passwordRef}
                     buttonRef={buttonRef}
                     hidePassword={hidePassword}
                     setHidePassword={setHidePassword}
                     colorsRef={colorsRef}
                     timeBetween={timeBetween}
                     />;
}

export default SignInFormFunc;
