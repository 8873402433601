import React, { Component, useContext  } from "react";
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../../../AppContext';

import DeleteModal from '../../Modals/DeleteModal.js';
import FaqEditModal from '../../Modals/FaqEditModal.js';

import { MDBContainer,
         MDBBtn,
         MDBIcon,
         MDBSwitch,
         MDBDatatable,
         MDBAlert,
         MDBRow,
         MDBCol
       } from 'mdb-react-ui-kit';


class AllFAQTable extends Component {

  constructor(props) {
    super(props);
    this.state = {faqList:[]}
  }

  componentDidMount() {
    this.onGetAllFAQ();
  }

  componentDidUpdate(prewProps, prewState) {
    if(this.state.faqGroupId !== this.props.faqGroupId){
      this.onGetAllFAQ(this.props.faqGroupId);
      this.setState({faqGroupId: this.props.faqGroupId})
    }
  }

  onGetAllFAQ = () => {
    let self = this;
    this.props.context.interAxios.get('/admin/faq', function(response) {
      self.setState({faqList: response.data, serialNumberList: response.data.map((k) => { return (k.weight + 1)})});
    });
  }

  onCreateNewFAQ = (question, answer, weight) => {
    let self = this;
    this.props.context.interAxios.post('/admin/faq',{
                                                  weight: weight-1,
                                                  question: question,
                                                  answer: answer,
                                                  active: false
                                              }, function(response) {
      self.onGetAllFAQ();
    });
  }

  onUpdateFAQById = (id, question, answer, weight) => {
    let self = this;
    this.props.context.interAxios.put('/admin/faq/' + id, {
                                                  weight: weight-1,
                                                  question: question,
                                                  answer: answer,
                                                  active: false
                                              }, function(response) {
      self.onGetAllFAQ();
    });
  }

  onDeleteFAQById = (id) => {
    this.setState({alert: true, alertText:"Sie haben die FAQ erfolgreich gelöscht"})
    let self = this;
    this.props.context.interAxios.delete('/admin/faq/' + id, function(response) {
      self.onGetAllFAQ();
    });
  }

  handleSwitchChange = (id, active) => {
    let self = this;
    this.props.context.interAxios.put('/admin/faq/' + id + '/active', !active, { headers: {'Content-Type': 'application/json'}},
     function(response) {
      self.onGetAllFAQ();
    })

  };

  showFaqEditModal = (index) => {
    this.setState({ faq: this.state.faqList[index],
      itemsCount: this.state.faqList.length,
                    oldPosition: index,
                    showFaqEditModal: true,
                    toggleBasisModal: true,
                    status: "GYIK módosítás"
                  })
  }

  onNewFaqModal = () => {
    this.setState({ faq: undefined,
                    itemsCount: this.state.faqList.length + 1,
                    oldPosition: undefined,
                    showFaqEditModal: true,
                    toggleBasisModal: true,
                    status: "Új GYIK"
                  })
  }

  hideFaqEditModal = () => {
    this.setState({faqId: "", showFaqEditModal: false})
  }

  showDeleteFAQByIdModal = (id) => {
    this.setState({faqId: id, showDeleteModal: true, status: "FAQ törlése", modalText: "Valóban törölni szeretné a kérdést?"})
  }

  hideDeleteFAQByIdModal = () => {
    this.setState({faqId: "", showDeleteModal: false})
  }

  truncateString = (str, num) => {
		if (str.length <= num) {
			return str
  		}
  			return str.slice(0, num) + '...'
	}

  render(){
    let self = this;
    let actionData = {
    columns: [
      { label: 'Sorszám', field: 'weight' },
      { label: 'Kérdés', field: 'question' },
      { label: 'Válasz', field: 'answer' },
      { label: 'Állapot', field: 'active'},
      { label: 'Lehetőségek', field: 'aktionen', sort: false },
    ],
    rows: this.state.faqList.map((row, n) => {
      return {
        weight: (
          <div className="d-flex align-items-center justify-content-center" style={{height: "100%"}}> {n+1} </div>),
        question: (<div dangerouslySetInnerHTML={{__html: row.question}}></div>),
        answer: (<div dangerouslySetInnerHTML={{__html: this.truncateString(row.answer, 50)}}></div>),
        active: (
          <div className="d-flex align-items-center justify-content-center">
            <MDBSwitch key={n} id="chatfilter" className="form-row" checked={row.active} onChange={() => this.handleSwitchChange(row.id, row.active)}
            />
            <span >{row.active ? "Aktiv" : "Inaktiv"}</span>
        </div>),
        aktionen: (
          <>
          <MDBBtn className='fw-bold' color='link' size='sm' rippleColor='dark' onClick={() => this.showFaqEditModal(n)}>
            <MDBIcon fas icon="pencil-alt"  color="info"/>
          </MDBBtn>
          <MDBBtn className='fw-bold' color='link' size='sm' rippleColor='dark' onClick={() => this.showDeleteFAQByIdModal(row.id)}>
            <MDBIcon fas icon="trash-alt" color="danger"/>
          </MDBBtn>
          </>
        ),
      };
    }),
  };
    return(
      <MDBContainer fluid className="p-0">
        <MDBRow className="pt-5 py-3">
          <MDBCol md="6" className="d-flex align-items-center justify-content-start">
          <h1 className='m-0'>GYIK lista</h1>
          </MDBCol>
          <MDBCol md="6" className="d-flex align-items-center justify-content-end">
          <MDBBtn type="btn" className="add-new" onClick={this.onNewFaqModal}>
            Új GYIK létrehozása
          </MDBBtn>
          </MDBCol>
        </MDBRow>
        <section>

          <div className='shadow-4 rounded-5 overflow-hidden faq-table'>
            <MDBDatatable
                      hover
                      data={actionData}
                      ofText='összesen'
                      rowsText='Megjelenítés oldalanként'
                      allText='Összes'
                      format={(field) => {
                        if(field==='weight') {
                          return {width: "125px"}
                        }
                        if(field==='active') {
                          return {width: "130px"}
                        }
                        if(field==='aktionen') {
                          return {width: "130px"}
                        }
                        if(field==='question') {
                          return {width: "250px"}
                        }
                      }
                      }
            />
          </div>
        </section>
        <MDBAlert
          show={this.state.alert}
          color='primary'
          autohide
          position='top-center'
          offset={50}
          delay={3000}
          appendToBody
          onClosed={() => { self.setState({alert: false, alertText: ""})}}
        >{this.state.alertText}</MDBAlert>
        {this.state.showFaqEditModal ?
        <FaqEditModal hideModal={this.hideFaqEditModal}
                       showModal={this.state.showFaqEditModal}
                       modalTyp={this.state.status}
                       faq={this.state.faq}
                       onUpdateFAQById={this.onUpdateFAQById}
                       onCreateNewFAQ={this.onCreateNewFAQ}
                       itemsCount={this.state.itemsCount}
                       oldPosition={this.state.oldPosition}
                       toggleBasisModal={this.state.toggleBasisModal}
                       context={this.props.context}
                     /> : false}
      {this.state.showDeleteModal ?
       <DeleteModal hideModal={this.hideDeleteFAQByIdModal}
                    showModal={this.state.showDeleteModal}
                    modalTyp={this.state.status}
                    faqId={this.state.faqId}
                    onDeleteFAQById={this.onDeleteFAQById}
                    modalText={this.state.modalText}
                    /> : false}
      </MDBContainer>
    );
  }

}
export default function AllFAQTableFunc(props) {
    const context = useContext(AppContext);
    let location = useLocation();
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    return <AllFAQTable context={context}
                        searchParams={searchParams}
                        navigate={navigate}
                        location={location}
                        faqGroupId={props.faqGroupId}
                        faqGroupAudience={props.faqGroupAudience}/>;
}
