// react imports
import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import CarouselComponentProgramok from '../carousel/CarouselComponentProgramok.js'
import alakreform_icon from '../../assets/media/icons/alakreform.png';
// CUSTOM COMPONENTS
import AppContext from '../../AppContext';
import Cookies from 'universal-cookie';
import { MDBContainer, MDBIcon } from "mdb-react-ui-kit";
const cookies = new Cookies();

const ProgramListaPage = (props) => {


  // REACT ROUTER PARAMS - for processing url
  const [searchParams] = useSearchParams();
  const context = useContext(AppContext);
  

  // STATES
  const [programList, setProgramList] = useState([]);
  const [isInland, setIsInland] = useState(false);
  const [isAbroad, setIsAbroad] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    
        context.interAxios.get('/camp', function(response) {
            console.log(response.data)
            setProgramList(response.data);
            setIsInland(response.data.filter(k => k.location === 'INLAND').length > 0)
            setIsAbroad(response.data.filter(k => k.location === 'ABROAD').length > 0)
          });
   
  }, []);
  
  
  return (
    <div>
        
        <section className={context.getState().liveTime !== undefined  ? "programlista-section live" : "programlista-section"}>
        {programList.length > 0 ? 
            <MDBContainer fluid style={{maxWidth: "1170px"}}>
                {isInland === true && (<div  className="pb-5">
                    <div className="events-location">Belföldi táborok</div>
                    {programList.filter(k => k.location === 'INLAND').map((k,n) => {return (
                        <div key={n}>
                            <img className="coverimage" src={k.coverimage.url} alt=""/>
                            <div className="title">
                                <span>{k.title}</span>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: k.description}}></div>
                            {k.url !== null && k.url !== "" && (
                                <button type="button" className="rergistration-button green my-4" onClick={() => window.open(k.url, '_self').focus()}><span>Részletek, jelentkezés</span></button>
                            )}
                            {k.images !== null && k.images.length>0 && (<>
                                <div  className="gallery-title" >
                                    <span>Galéria</span>
                                </div>
                                <CarouselComponentProgramok gallery={k.images} openLightboxlick={() => {setSliderImages(k.images); setOpen(!open)}}/>
                            </>)}
                            <h2><span><MDBIcon fab icon="galactic-senate" /></span></h2>
                        </div>
                    )})}
                </div>)}
                {isAbroad === true && (<div  className="pb-5 pt-5">
                    <div className="events-location">Külföldi táborok</div>
                    {programList.filter(k => k.location === 'ABROAD').map((k,n) => {return (
                        <div key={n} className="pb-5">
                            <img className="coverimage" src={k.coverimage.url} alt=""/>
                            <div className="title">
                                <span>{k.title}</span>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: k.description}}></div>
                            {k.url !== null && k.url !== "" && (
                                <button type="button" className="rergistration-button green my-4" onClick={() => window.open(k.url, '_self').focus()}><span>Részletek, jelentkezés</span></button>
                            )}
                            {k.images !== null && k.images.length>0 && (<>
                                <div  className="gallery-title" >
                                    <span>Galéria</span>
                                </div>
                                <CarouselComponentProgramok gallery={k.images} openLightboxlick={() => {setSliderImages(k.images); setOpen(!open)}}/>
                            </>)}
                            <h2><span><MDBIcon fab icon="galactic-senate" /></span></h2>
                        </div>
                    )})}
                </div>
                    
                )}
            </MDBContainer>
        :

            <MDBContainer fluid style={{maxWidth: "1170px"}}>
                
                <div className="events-location">Jelenleg nincsenek elérhető programok</div>
                   
            </MDBContainer>
            }
        </section>
   
        
       
        <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={sliderImages.map(k => {return ({src: k.url})})}
            plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
        />
    </div>
  );
};

export default ProgramListaPage;
