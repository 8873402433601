import React, { Component, useContext, useRef  } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext.js';


import CacheForm from './CacheForm.js'

import {
  MDBContainer
} from 'mdb-react-ui-kit';

class CachePage extends Component {

  constructor(props) {
  	super(props);
    this.state = {isNewProgram: false, programList:  []};

	}

  componentDidMount() {
    this.onGetAllProgram();
  }

  onNewProgram = () => {
    this.setState({isNewProgram: true})
  }

  onGetAllProgram = () => {
    let self = this;
    console.log("belép")
    this.props.context.interAxios.get('/camp', function(response) {
      self.setState({programList: response.data});
    });
  }


  render(){
    return (
      <div style={{paddingLeft: "290px"}}>
        <MDBContainer className='pt-4 pt-lg-5'>
            <CacheForm context={this.props.context} onGetAllProgram={this.onGetAllProgram} onCloseNewProgram={() => {this.setState({isNewProgram: false})}}/>
          <div className="p-5" style={{height: "50px"}}/>
        </MDBContainer>
      </div>
    );
  }

}

export default function CachePageFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const nodeRef = useRef(null)
    return <CachePage context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef} />;
}
