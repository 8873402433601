// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import ImageUploader from './image-uploader/ImageUploader';
import SingleImageUploader from './image-uploader/SingleImageUploader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBDatepicker,
  MDBContainer,
  MDBCard,
  MDBSelect 
} from 'mdb-react-ui-kit';


// STYLE IMPORTS
import "react-toggle/style.css";
const customWeekdaysNarrow = ['V', 'H', 'K', 'Sz', 'Cs', 'P', 'Sz'];
const customMonthsFull = [ 'Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December', ]

class ProgramForm extends Component {
 
  constructor(props) {
    super(props);
    this.state = {coverimage: [], images: [], location: "INLAND"};
    };

    onCustomer = (e) => {
      e.preventDefault();
        let self = this;
        const formData = new FormData
        
        formData.append("title", this.state.title);
        formData.append("description", this.state.description);
        formData.append("url", this.state.url);
        formData.append("startDate", this.state.startDate);
        formData.append("location", this.state.location);
        formData.append("coverimage", this.state.coverimage[0].file, this.state.coverimage[0].file.name);
        for(let i=0; i<this.state.images.length; i++){
          formData.append("images[" + i + "]", this.state.images[i].file, this.state.images[i].file.name);
        }
        
        //
  
       this.props.context.interAxios.post('/admin/camp', formData, { headers: {'Content-Type': 'multipart/form-data'}},
        function(response) {
          self.setState({
            title: "",
            description: "",
            url: "",
            startDate: "",
            location: "",
          })
          self.setState({coverimage: [], images: []})
          self.props.onCloseNewProgram();
          self.props.onGetAllProgram();
        });
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    onDescriptionChange = (editorState) => {
      this.setState({description: editorState});
    }

    setCoverImage = (images) => {
      this.setState({coverimage: images});
    }

    setImages = (images) => {
      this.setState({images: images});
    }
  
  render() {
    return (<div>
    <MDBContainer className='pt-4 pt-lg-5 px-0'>
      <MDBCard style={{ width: '100%', padding: "25px"}}>
              <form onSubmit={(e) => this.onCustomer(e)} className="profile-form" autoComplete="off">
                <div className="white-panel">
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='4' className="px-0">
                      <label htmlFor="title">Title <span>*</span></label>
                      <input
                        id='title'
                        name='title'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.title}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='12' className="px-0">
                      <label htmlFor="name">Program leírása <span>*</span></label>
                      <CKEditor
                        editor={ ClassicEditor }
                        data={this.state.description}
                        config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
                        onChange={
                          ( event, editor ) => {
                            const data = editor.getData();
                            this.onDescriptionChange(data);
                          }
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='4' className="px-0">
                      <label htmlFor="url">Link <span>*</span></label>
                      <input
                        id='url'
                        name='url'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.url}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='4' className="px-0 birth-field">
                      <label htmlFor="startDate">Dátum <span>*</span></label>
                      <MDBDatepicker  inline
                        customIcon='fa-regular fa-calendar-alt fa-lg'
                        format='yyyy-mm-dd'
                        min={new Date()}
                        monthsFull={customMonthsFull}
                        weekdaysNarrow={customWeekdaysNarrow}
                        value={this.state.startDate}
                        
                        onChange={val => {
                          this.setState({startDate: val});
                        }}/>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='4' className="px-0">
                      <label htmlFor="location">Location <span>*</span></label>
                      <MDBSelect
                        data={[
                          { text: 'INLAND', value: 1 },
                          { text: 'ABROAD', value: 2 }
                        ]}
                        onValueChange={(val) => this.setState({location: val.text})}
                      />
                    </MDBCol>
                    
                  </MDBRow>
                  <MDBRow className="pb-2 m-0">
                   
                    <MDBCol md='4' className="px-0">
                      <label htmlFor="coverimage">Borítókép feltöltés <span>*</span></label>
                      <SingleImageUploader
                        selectedImages={this.state.coverimage.length > 0 ? this.state.coverimage : []}
                        setSelectedImages={this.setCoverImage}
                        
                         uploadBtnMessage={"Képek feltöltése"}
                     />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pb-2 m-0">
                   
                   <MDBCol md='4' className="px-0">
                     <label htmlFor="images">Képek feltöltés <span>*</span></label>
                     
                     <ImageUploader
                           selectedImages={this.state.images.length > 0 ? this.state.images : []}
                           setSelectedImages={this.setImages}
                          uploadBtnMessage={"Borítókép feltöltése"}
                      />
                   </MDBCol>
                 </MDBRow>
                

                 
                  <MDBRow className="pb-5 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start px-0 pb-5" >
                          <div>
                            <button type="submit" className="rergistration-button green" >
                            <span>Program mentése</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>
           
                
                  </div>
              </form>
      </MDBCard>
      <div className="p-5" style={{height: "50px"}}/>
    </MDBContainer>
      </div>
    );
  }
};

function ProgramFormFunc(props) {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const utalasRef = useRef();
  const emailRef = useRef();
  const phonenumberRef = useRef();
  const addressRef = useRef();
  const cityRef = useRef();
  const zipCodeRef = useRef();
  const birthPlaceRef = useRef();
  const birthDateRef = useRef();
  const genderRef = useRef();
  const anyjaneveRef = useRef();
  const szuletesinevRef = useRef();
  const nameRef = useRef()
  return <ProgramForm searchParams={searchParams} 
                      navigate={navigate} 
                      context={props.context} 
                      utalasRef={utalasRef}
                      emailRef={emailRef}
                      phonenumberRef={phonenumberRef}
                      addressRef={addressRef}
                      cityRef={cityRef}
                      zipCodeRef={zipCodeRef}
                      birthPlaceRef={birthPlaceRef}
                      birthDateRef={birthDateRef}
                      genderRef={genderRef}
                      anyjaneveRef={anyjaneveRef}
                      szuletesinevRef={szuletesinevRef}
                      nameRef={nameRef}
                      onGetAllProgram={props.onGetAllProgram}
                      onCloseNewProgram={props.onCloseNewProgram}
                      />;
}

export default ProgramFormFunc;
