import React, { Component  } from "react";
import ModalTemplate from "./ModalTemplate";

class DeleteModal extends Component {

	constructor(props) {
    super(props);
    this.state = {
      btnForm: [  {classname: "cancel" , onclick: this.props.hideModal , text: "Mégsem"},
                  {classname: "save" , onclick: this.internalHandleSave , text: "Törlés"}
               ]
    };
  }

  componentDidMount() {
  }

  internalHandleSave = () => {
    this.props.onDeleteFAQById(this.props.faqId);
    this.props.hideModal();
  }


	render() {

    return (
      <ModalTemplate
  				{...this.props}
  				internalHandleSave={this.internalHandleSave}
          btnForm={this.state.btnForm}
          modalId={""}
  			>
        {this.props.modalText}

      </ModalTemplate>
  );
  }
}
export default DeleteModal;
