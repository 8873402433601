// REACT IMPORTS
import React, { useContext, Component } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppContext from '../../AppContext';
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBAlert
} from 'mdb-react-ui-kit';



// STYLE IMPORTS
import "react-toggle/style.css";

class ForgotPasswordForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "", alert: false, alertText: ""
                   
      };
    };

    onSendEmail = (e) => {
      e.preventDefault();
      let self = this;
      if(this.state.email !== ""){
        this.props.context.interAxios.post('/auth/' + this.state.email + '/reset-password', {email:this.state.email}, function(response) {
          self.props.hideModal();
        });
      }else{
        this.setState({alert: true, alertText: "Add meg az E-mail címedet!"});
      }
      
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

  
  render() {
    return (<>
              <form onSubmit={(e) => this.onSendEmail(e)} className="signin-form" autoComplete="off">
                <div className="white-panel">
                  {/** EMAIL **/}
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="firstName">E-mail cím</label><br/>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        required
                      />
                    </MDBCol>
                  </MDBRow>


                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-center" >
                      
                          <div>
                            <button type="submit" className="rergistration-button modal-button green" >
                            <span>Új jelszó küldése</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>

                </div>
              </form>
              <MDBAlert
                  show={alert.alert}
                  color='primary'
                  autohide
                  position='top-center'
                  offset={50}
                  delay={4000}
                  appendToBody
                  onClosed={() => { setAlert({alert: false, alertText: ""})}}
              >{alert.alertText}</MDBAlert>
      </>
    );
  }
};

function ForgotPasswordFormFunc(props) {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const context = useContext(AppContext);
  return <ForgotPasswordForm searchParams={searchParams} navigate={navigate} hideModal={props.hideModal} context={context}/>;
}

export default ForgotPasswordFormFunc;
