// react imports
import React, { useContext, useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import prev from '../../assets/media/icons/prev.png'
import video_clock from '../../assets/media/icons/video-clock.png'
import video_play from '../../assets/media/icons/video-play.png'
import bookmark_filled from '../../assets/media/icons/bookmark-filled.png'
import bookmark_empty from '../../assets/media/icons/bookmark-empty.png'
import search from '../../assets/media/icons/search.png'
import gymflix_logo from '../../assets/media/logo/GYMFLIX.png'
import trainer_background from '../../assets/media/images/trainer-background.png'
import video from '../../assets/media/videos/video_1.mp4'
import clock from '../../assets/media/icons/clock.png'
// import Swiper and modules styles
import 'swiper/css';
import AppContext from '../../AppContext';
import { MDBContainer,
          MDBRow,
          MDBCol,
          MDBInput
          } from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const CategoryDetailPage = (props) => {

  const location = useLocation();
  const context = useContext(AppContext);

  // react router navigation
  const navigate = useNavigate();

  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);
  const [videoHeight, setVideoHeight] = useState(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
  const [open, setOpen] = useState(false);
  const [starttime, setStarttime] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);
  const [trainer, setTrainer] = useState(location.state?.trainer !== undefined ? location.state?.trainer : {})
  const [videoList, setVideoList] = useState(location.state?.videoList !== undefined ? location.state?.videoList : {})
  const [trainerList, setTrainerList] = useState(location.state?.trainerList !== undefined ? location.state?.trainerList : {})
  const [categoryName, setCategoryName] = useState(location.state?.categoryName !== undefined ? location.state?.categoryName : "")
  const [searchText, setSearchText] = useState("");
  


  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
    setVideoHeight(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
 
  };


  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    setTimeout(() => {setStarttime(true) }, 100)
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);


  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 300)
  }, []);

  const onSearchText  = (event) => {
    setSearchText(event.target.value);
  };

  const truncateString = (str, num) => {
    
    if (str.length <= num) {
      
        return str
      }
          return str.slice(0, num) + '...'
  }
 
  return (
        <>
          <button className="back-button-trainer" type="button" onClick={() => navigate('/fooldal')}>
            <img src={prev} alt="back"/><span>Back</span>
          </button>
          <section className="categorydetail-section">
           
            <MDBContainer fluid style={{maxWidth: "1670px"}}>
              <div className="trainer-name">
                <span >{categoryName}</span>
              </div>
            </MDBContainer>  
          </section>
          <section className="more-videos-section">
            <MDBContainer fluid style={{maxWidth: "1670px"}}>
            <div className="input-div d-none d-lg-block">
                <MDBInput label={<><img src={search} alt="search"/><span>Keresés</span></>} id='form1' type='text' value={searchText} onChange={onSearchText} />
            </div>
            <MDBRow  className="m-0 pb-5">
              {videoList && categoryName && (<>
                {videoList.filter(k => (k.category === categoryName)).filter(k => k.name.toLowerCase().includes(searchText.toLowerCase())).map((k,n) => {
                  let array = k.name.split(" ")
                  if(k.name.split("dátum:").length > 1){
                    var title_date = k.name.split(" ")[0];
                    var title_date_final = title_date.split(":")[1];
                    var title = k.name.substr(title_date.length + 1);
                }else {
                    var title_date = "";
                    var title = k.name;
                }
                  return(
                  <MDBCol lg="3" md="6" size="12" key={n} className="pt-5 px-0">
                    <div className="videocard-more px-2" key={n} >
                      <div className="background-img">
                        <img src={k.pictureUrl} alt="thumbnail-image" id="thumbnail-image" className="thumbnail-image"/>
                        
                        <div className="timer">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={clock}/>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <span>{parseInt(k.duration/60)}</span>
                            </div>
                        </div>
                        
                        <div className="video-card-layout d-flex justify-content-start align-items-end">
                            <div className="text-position">
                                <span className={k.category === "Teljes test edzés" ? " title green" : k.category === "Páros edzés" ? "title pink" : "title purple"}>
                                    {title}
                                </span>
                            </div>
                        </div>
                        
                        <div className="description-hover">
                         
                          <button className="play-button" type="button" onClick={() => { navigate('/videodetail', { state: { video: k} })}}><img src={video_play} alt="player"/></button>

                          <div className="time">
                              <img src={video_clock} alt="timer"/>
                              <span>{parseInt(k.duration/60) + "minutes"}</span>
                          </div>
                          
                          <div className="bookmark-div">
                            <div className="more-text">
                                <span className="title-2">{truncateString(k.name, 40)}</span>
                            </div>
                            <button className="bookmark-button" type="button" onClick={k.bookmarked ? 
                                              () => {props.onDeleteVideoFromBookmark(k.id)} : () => {props.onPutVideoToBookmark(k.id)}}>
                              <img src={k.bookmarked ? bookmark_filled : bookmark_empty} alt="bookmark" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </MDBCol>
                )})}
              </>

              )}
                        
            </MDBRow>
            </MDBContainer>
          </section>
        </>
  );
};

export default CategoryDetailPage;
