import React, { Component, useContext, useState  } from "react";
import { Link } from 'react-router-dom';
import AppContext from '../../../../AppContext';

import logo from '../../../../assets/media/logo/logo.png'

import {
  MDBRipple,
  MDBIcon,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem
} from 'mdb-react-ui-kit';

function SideNav(props) {
  const lastPathNameElement = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
  const [showV1, setShowV1] = useState(props.isSideNavOpen);
  const [showV1Backdrop, setShowV1Backdrop] = useState(false);
  const [collapseOpened, setCollapseOpened] = useState(lastPathNameElement);
  const context = useContext(AppContext);
  
  
  const toggleAccordion = (value) => {
    value !== collapseOpened ? setCollapseOpened(value) : setCollapseOpened('');
  };
  
  
  return (  <div className="sidenav-form">
   
              <MDBSideNav
                backdrop={showV1Backdrop}
                constant={!showV1Backdrop}
                isOpen={true}
                absolute
                getOpenState={(e) => setShowV1(e)}
              >
                <MDBSideNavMenu>
                  <MDBRipple className='d-flex justify-content-center py-5' href='#'>
                    <img
                      id='logo'
                      src={logo}
                      alt='Logo'
                      draggable='false'
                      style={{width: "50%"}}
                    />
                  </MDBRipple>
              
                 
                  {/*FAQ*/}
                  <MDBSideNavItem>
                    <Link to="faq">
                      <MDBIcon fas icon='comments' className='fa-fw me-3' />
                      <span className='sidenav-non-slim'>FAQ</span>
                    </Link>
                  </MDBSideNavItem>
                   {/*Programok*/}
                   <MDBSideNavItem>
                    <Link to="programok">
                      <MDBIcon fas icon='comments' className='fa-fw me-3' />
                      <span className='sidenav-non-slim'>Programok</span>
                    </Link>
                  </MDBSideNavItem>
                  {/*FAQ*/}
                  <MDBSideNavItem>
                    <Link to="activeclient">
                      <MDBIcon fas icon='user-alt' className='fa-fw me-3' />
                      <span className='sidenav-non-slim'>Aktív előfizetők</span>
                    </Link>
                  </MDBSideNavItem>
                  <MDBSideNavItem>
                    <Link to="inactiveclient">
                      <MDBIcon fas icon='user-alt' className='fa-fw me-3' />
                      <span className='sidenav-non-slim'>Inaktív előfizetők</span>
                    </Link>
                  </MDBSideNavItem>
                  <MDBSideNavItem>
                    <Link to="cache">
                      <MDBIcon fas icon='user-alt' className='fa-fw me-3' />
                      <span className='sidenav-non-slim'>cache törlés</span>
                    </Link>
                  </MDBSideNavItem>
                  <MDBSideNavItem>
                    <Link to="newsletter">
                      <MDBIcon fas icon='envelope' className='fa-fw me-3' />
                      <span className='sidenav-non-slim'>Feliratkozás hírlevélre</span>
                    </Link>
                  </MDBSideNavItem>
                  <MDBSideNavItem>
                    <Link to="imageupload">
                      <MDBIcon fas icon='upload' className='fa-fw me-3' />
                      <span className='sidenav-non-slim'>Header Image Upload</span>
                    </Link>
                  </MDBSideNavItem>
                  <MDBSideNavItem>
                    <Link to="livecalendar">
                      <MDBIcon fas icon='headset' className='fa-fw me-3' />
                      <span className='sidenav-non-slim'>Live Calendar</span>
                    </Link>
                  </MDBSideNavItem>
                </MDBSideNavMenu>
              </MDBSideNav>
            </div>

  );
}

export default SideNav;
